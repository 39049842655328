import { fromJS } from 'immutable';
import React from "react";

interface IDiceContext {
    gameData: any;
    setGameData: any;
    ticket: any;
    setTicket: any;
    status: any;
    setStatus: any;
    currency: string;
    setCurrency: any;
    balance: number;
    setBalance: any;
}

export const DiceContext = React.createContext<IDiceContext>({
    gameData: fromJS({}),
    setGameData: () => null,
    ticket: 0,
    setTicket: () => null,
    status: "",
    setStatus: () => null,
    currency: "",
    setCurrency: () => null,
    balance: 0,
    setBalance: () => null,
});
