import {Routes, Route} from "react-router-dom";
import PlinkoPage from "../pages/PlinkoPage";
import TowersPage from "../pages/TowersPage";
import MinesPage from "../pages/MinesPage";
import DicePage from "../pages/DicePage";

export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/dice" element={<DicePage/>}/>
            <Route path="/plinko" element={<PlinkoPage/>}/>
            <Route path="/mines" element={<MinesPage/>}/>
            <Route path="/towers" element={<TowersPage/>}/>
        </Routes>
    );
};
