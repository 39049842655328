import styles from "./PlayField.module.css";
import {BetWindow} from "../../../../components/UI/BetWindow/BetWindow";
import {useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Square} from "../Square/Square";
import DimensionsContext from "../../../../context/dimensions/dimensions";
import {useSnackbar} from "../../../../hooks/useSnackbar";
import clickaudio from "../../../../components/shared/sounds/Click.mp3";
import {MinesContext} from "../../../../context/mines/mines";
import {HttpContext} from "../../../../context/http/http";
import Multipliers from "../../../../components/UI/Multipliers/Multipliers";
import leftTopPic from "../../images/leftTopPic.svg";
import leftBottomPic from "../../images/leftBottomPic.svg";
import cubGroup from "../../images/cubGroup.svg";
import cubGroupMin from "../../images/cubGroupMin.svg";
import { debounce } from 'lodash';

type ClickQueueItem = {
    id: number;
};


export const PlayField = () => {
    const {
        gameID,
        setGameID,
        flagField,
        isLose,
        setIsLose,
        isWin,
        setIsWin,
        showAllSquares,
        setShowAllSquares,
        numMines,
        setNumMines,
        mines,
        setMines,
        multiplier,
        setMultiplier,
        lastMultipliers,
        balance,
        setBalance,
        actualBet,
        setActualBet,
    } = useContext(MinesContext);

    const soundRef = useRef<HTMLAudioElement | null>(null);

    const playSound = () => {
        if (soundRef.current) {
            soundRef.current.currentTime = 0;
            soundRef.current.play().catch((error) => {
                console.log("Chrome cannot play sound without user interaction first");
            });
        }
    };

    const {x} = useContext(DimensionsContext);
    const {notify} = useSnackbar();

    const { hasBalance, currency, getMinesGameState, createMines, actionMines} = useContext(HttpContext);

    const clickQueue = useRef<ClickQueueItem[]>([]);
    const isProcessingClick = useRef(false);

    const [bet, setBet] = useState<string>(actualBet.toString());

    useEffect(() => {
        getMinesGameState().finally(() => {});
        soundRef.current = new Audio(clickaudio);
        soundRef.current.volume = 0.5;
        soundRef.current.preload = "auto";
    }, []);

    useEffect(() => {
        // Perform any necessary actions with the updated gameID
    }, [gameID, balance, currency]);

    useEffect(() => {
        // Perform any necessary actions when mines, isLose, or isWin change
    }, [mines, isLose, isWin]);

    useLayoutEffect(() => {
        setMines(
            Array.from({length: 25}, (_, i) => i + 1).map((item) => {
                return {id: item, isMine: false, isChecked: false};
            })
        );
    }, []);

    const generateMines = async () => {
        setShowAllSquares(false);
        setMines(
            Array.from({length: 25}, (_, i) => i + 1).map((item) => {
                return {id: item, isMine: false, isChecked: false};
            })
        );
        // if (actualBet <= 0) {
        //     notify("Bet value can't be 0", "error");
        //     return;
        // }
        if (!hasBalance(actualBet)) {
            notify("You don't have enough balance", "error");
            return;
        }

        playSound();
        await createMines(actualBet, numMines);
        // Clear the clickQueue and start fresh
        clickQueue.current = [];
    };

    const handleDebouncedClick = debounce((id: number) => {
        handleClick(id);
    }, 75, { 'leading': true, 'trailing': false });

    const handleClick = (id: number) => {
        if (!clickQueue.current.some(item => item.id === id)) {
            setMines((mines: any[]) => mines.map(mine =>
                mine.id === id ? { ...mine, isLoading: true } : mine
            ));
            clickQueue.current.push({ id });
            processClickQueue();
        }
    };

    const handleFinishGame = useCallback(() => {
        actionMines(gameID, 'cashout', 0,0,0).finally(() => {});
    }, [gameID, actionMines, setGameID]);


    const processClickQueue = useCallback(() => {
        if (isProcessingClick.current || clickQueue.current.length === 0 || isLose || isWin) {
            return;
        }

        isProcessingClick.current = true;

        let nextAction = clickQueue.current.shift();
        while (nextAction && mines[nextAction.id - 1].isChecked) {
            nextAction = clickQueue.current.shift(); // Skip already checked mines
        }

        if (!nextAction) {
            isProcessingClick.current = false;
            return;
        }

        const { id } = nextAction;
        const indexY = Math.floor((id - 1) / 5);
        const indexX = (id - 1) % 5;

        // playSound();

        actionMines(gameID, 'reveal', id - 1, indexX, indexY).finally(() => {
            setMines((mines: any[]) => mines.map(mine =>
                mine.id === id ? { ...mine, isLoading: false } : mine
            ));
            isProcessingClick.current = false;
            processClickQueue();
        });
    }, [gameID, actionMines, mines, isLose, isWin]);


    const resetGame = () => {
        setMultiplier(1.0);
        setIsLose(false);
        setIsWin(false);
        setShowAllSquares(false);
        setMines(
            Array.from({length: 25}, (_, i) => i + 1).map((item) => {
                return {id: item, isMine: false, isChecked: false};
            })
        );
    };

    return x >= 960 ? (
        <div className={styles.wrapper}>
            <div className={styles.leftSide}>
                <BetWindow
                    game="mines"
                    multiplier={multiplier}
                    isEnabledSquare={flagField}
                    bet={bet}
                    actualBet={actualBet}
                    setBet={setBet}
                    setActualBet={setActualBet}
                    startGame={generateMines}
                    options={numMines}
                    setOptions={setNumMines}
                    cashOut={handleFinishGame}
                    isLose={isLose}
                    isWin={isWin}
                    resetGame={resetGame}
                    showAllSquares={showAllSquares}
                    balance={balance}
                    currency={currency}
                />
                {/*<img src={leftTopPic} className={styles.leftTopPic} alt="lefttop"/>*/}
                {/*<img*/}
                {/*    src={leftBottomPic}*/}
                {/*    className={styles.leftBottomPic}*/}
                {/*    alt="leftbottom"*/}
                {/*/>*/}
            </div>
            <div className={styles.rightSide}>
                <Multipliers multipliers={lastMultipliers}/>
                <div className={styles.cubWrapper}>
                    {mines.map((item: any) => {
                        return (
                            <Square
                                key={item.id}
                                isLoading={item.isLoading}
                                isChecked={item.isChecked}
                                onClick={() => handleDebouncedClick(item.id)}
                                disabled={!flagField || item.isChecked}
                                isMine={item.isMine}
                                position={item.id}
                                showAllSquares={showAllSquares}
                            />
                        );
                    })}
                </div>
                {/*<img src={cubGroup} className={styles.cubGroup} alt="block"/>*/}
            </div>
        </div>
    ) : (
        <div className={styles.wrapperMin}>
            <div className={styles.rightSide}>
                <div className={styles.cubWrapper}>
                    {mines.map((item: any) => {
                        return (
                            <Square
                                key={item.id}
                                isLoading={item.isLoading}
                                isChecked={item.isChecked}
                                onClick={() => handleDebouncedClick(item.id)}
                                disabled={!flagField || item.isChecked}
                                isMine={item.isMine}
                                position={item.id}
                                showAllSquares={showAllSquares}
                            />
                        );
                    })}
                </div>
                {/*<img src={cubGroupMin} className={styles.cubGroupMin} alt="block"/>*/}
            </div>
            <div className={styles.leftSideMin}>
                <BetWindow
                    game="mines"
                    multiplier={multiplier}
                    isEnabledSquare={flagField}
                    bet={bet}
                    actualBet={actualBet}
                    setBet={setBet}
                    setActualBet={setActualBet}
                    startGame={generateMines}
                    cashOut={handleFinishGame}
                    options={numMines}
                    setOptions={setNumMines}
                    isLose={isLose}
                    isWin={isWin}
                    resetGame={resetGame}
                    showAllSquares={showAllSquares}
                    balance={balance}
                    currency={currency}
                />
            </div>
        </div>
    );
};
