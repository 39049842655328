import React from "react";

interface IPlinkoContext {
    gameData: any;
    setGameData: any;
    balance: number;
    setBalance: any;
}

export const PlinkoContext = React.createContext<IPlinkoContext>({
    gameData: null,
    setGameData: () => null,
    balance: 0,
    setBalance: () => null,
});
