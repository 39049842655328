import React from 'react';
import "./Multipliers.css";
import { motion } from "framer-motion";

interface IMultiplier {
    id: string;
    multiplier: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

interface IMultipliers {
    multipliers: IMultiplier[];
}

const Multipliers: React.FC<IMultipliers> = React.memo(({ multipliers }) => {
    return (
        <div className="multipliers-wrapper">
            <div className="multipliers">
                {multipliers.map((multiplier) => (
                    <motion.div
                        initial={{ y: -30 }}
                        animate={{ y: 0, transition: { duration: 0.2 } }}
                        key={multiplier.id}
                        className="multiplier"
                    >
                        {/* Ensure that `multiplier.multiplier` is a valid React component */}
                        <multiplier.multiplier viewBox="10 1 26 30" />
                    </motion.div>
                ))}
            </div>
        </div>
    );
});

export default Multipliers;
