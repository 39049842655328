import React from "react";
import {IMultiplier} from "../../Games/Towers/types";

interface IMinesContext {
    gameID: number;
    setGameID: any;
    flagField: boolean;
    setFlagField: any;
    isLose: boolean;
    setIsLose: any;
    isWin: boolean;
    setIsWin: any;
    showAllSquares: boolean;
    setShowAllSquares: any;
    numMines: number;
    setNumMines: any;
    mines: any;
    setMines: any;
    actualBet: number;
    setActualBet: any;
    multiplier: number;
    setMultiplier: any;
    lastMultipliers: IMultiplier[];
    setLastMultipliers: any;
    balance: number;
    setBalance: any;
    currency: string;
    setCurrency: any;
}

export const MinesContext = React.createContext<IMinesContext>({
    gameID: 0,
    setGameID: () => null,
    flagField: false,
    setFlagField: () => null,
    isLose: false,
    setIsLose: () => null,
    isWin: false,
    setIsWin: () => null,
    showAllSquares: false,
    setShowAllSquares: () => null,
    numMines: 3,
    setNumMines: () => null,
    mines: [],
    setMines: () => null,
    actualBet: 0,
    setActualBet: () => null,
    multiplier: 1.0,
    setMultiplier: () => null,
    lastMultipliers: [],
    setLastMultipliers: () => null,
    balance: 0,
    setBalance: () => null,
    currency: "",
    setCurrency: () => null,
});
