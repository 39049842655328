import {useState} from "react";
import {TowersContext} from "./towers";
import {IMultiplier, Mode} from "../../Games/Towers/types";

export default function TowersProvider({children}: any) {
    const [gameID, setGameID] = useState(0);
    const [flagField, setFlagField] = useState<boolean>(false);
    const [isLose, setIsLose] = useState<boolean>(false);
    const [isWin, setIsWin] = useState<boolean>(false);
    const [mode, setMode] = useState<Mode>("medium");
    const [showAllSquares, setShowAllSquares] = useState<boolean>(false);

    const [revealedTowers, setRevealedTowers] = useState(null);
    const [revealed, setRevealed] = useState(null);
    const [round, setRound] = useState(0);

    const [actualBet, setActualBet] = useState(0);
    const [multiplier, setMultiplier] = useState(1.0);
    const [lastMultipliers, setLastMultipliers] = useState<IMultiplier[]>([]);

    const [balance, setBalance] = useState(0);
    const [currency, setCurrency] = useState("USD");

    return (
        <TowersContext.Provider
            value={{
                gameID,
                setGameID,
                flagField,
                setFlagField,
                isLose,
                setIsLose,
                isWin,
                setIsWin,
                mode,
                setMode,
                showAllSquares,
                setShowAllSquares,
                revealedTowers,
                setRevealedTowers,
                revealed,
                setRevealed,
                round,
                setRound,
                actualBet,
                setActualBet,
                multiplier,
                setMultiplier,
                lastMultipliers,
                setLastMultipliers,
                balance,
                setBalance,
                currency,
                setCurrency,
            }}
        >
            {children}
        </TowersContext.Provider>
    );
}
