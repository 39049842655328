const pins = {
  startPins: 3,
  pinSize: 4,
  pinGap: 26
}

const ball = {
  ballSize: 7.5
}

const engine = {
  engineGravity: 2.5
}

const world = {
  width: 540,
  height: 530
}

export const config = {
  pins,
  ball,
  engine,
  world
}