import { Mode, Modes } from "./types";

export const getConfig = (mode: Mode) => {
  return modes[mode];
};

export const modes: Modes = {
  easy: { starting: [0, 0, 0, 0], numMines: 1, multiplier: 1.26 },
  medium: { starting: [0, 0, 0], numMines: 1, multiplier: 1.42 },
  hard: { starting: [0, 0], numMines: 1, multiplier: 1.9 },
  nightmare: { starting: [0, 0, 0, 0], numMines: 3, multiplier: 3.8 },
};
