import { PlayField } from "./components/PlayField/PlayField";
import styles from "./MineSweeper.module.css";

export const MineSweeper = () => {
  return (
    <div className={styles.wrapper}>
      <PlayField />
    </div>
  );
};
