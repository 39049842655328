import styles from "./Multipliers.module.css";
import { motion } from "framer-motion";

interface IMultipliers {
  multipliers: any[];
}

export default function Multipliers({ multipliers }: IMultipliers) {
  return (
    <div className={styles.multipliers_wrapper}>
      <div className={styles.multipliers}>
        {multipliers.map((multiplier) => (
          <motion.div
            initial={{ y: -30 }}
            animate={{ y: 0, transition: { duration: 0.2 } }}
            key={multiplier.id}
            className={
              multiplier.win
                ? `${styles.multiplier} ${styles.multiplier_win}`
                : `${styles.multiplier} ${styles.multiplier_lose}`
            }
          >
            <p>{multiplier.value.toFixed(2)}x</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
