import { ITowersLayers, Mode } from "../../types";
import FieldMedium from "../../images/fieldmedium.svg";
import FieldMediumSpecial from "../../images/fieldmediumspecial.svg";
import FieldSmall from "../../images/fieldsmall.svg";
import FieldSmallSpecial from "../../images/fieldsmallspecial.svg";
import FieldLarge from "../../images/fieldlarge.svg";
import FieldLargeSpecial from "../../images/fieldlargespecial.svg";
import BombMedium from "../../images/bombmedium.svg";
import BombSmall from "../../images/bombsmall.svg";
import BombLarge from "../../images/bomblarge.svg";
import SuccessMedium from "../../images/successmedium.svg";
import SuccessSmall from "../../images/successsmall.svg";
import SuccessLarge from "../../images/successlarge.svg";
import { towersBombGif } from "../../../../components/shared/gif";
import { motion } from "framer-motion";
import "./TowersField.css";

export interface IFieldsProps {
  fields: number[][];
  revealed: ITowersLayers | null;
  towers: ITowersLayers | null;
  round: number;
  handleOnClick: (index: number) => void;
  flag: boolean;
  mode: Mode;
  showAllSquares: boolean;
}

export function Fields(props: IFieldsProps) {
  return (
    <div className="towers-layers">
      {[...props.fields].reverse().map((layer: any, index: number) => {
        const statement =
          props.round !== 0
            ? !(
                !(8 - index !== props.round) && props.revealed![props.round - 1]
              ) || !props.flag
            : 8 - index !== props.round || !props.flag;
        return (
          <div key={index} className="towers-layer">
            {layer.map((field: any, innerIndex: number) => (
              <div
                key={innerIndex}
                style={
                  8 - index >= props.round || !props.flag
                    ? {}
                    : { opacity: 0.5 }
                }
                className="towers-field"
              >
                <button
                  className={
                    statement
                      ? "towers-field-button"
                      : "towers-field-button towers-field-live"
                  }
                  onClick={() => props.handleOnClick(innerIndex)}
                  disabled={statement}
                >
                  <FieldEl {...props} index={index} innerIndex={innerIndex} />
                </button>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

interface IFieldProps extends IFieldsProps {
  index: number;
  innerIndex: number;
  mode: Mode;
}

function FieldEl(props: IFieldProps) {
  if (props.showAllSquares) {
    if (
      props.towers &&
      props.towers[8 - props.index]?.fields[props.innerIndex] === -1
    ) {
      return (
        <>
          {props.mode === "hard" ? (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6, transition: { duration: 0.5 } }}
              src={BombLarge}
            ></motion.img>
          ) : (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6, transition: { duration: 0.5 } }}
              src={BombMedium}
            ></motion.img>
          )}
        </>
      );
    } else {
      return (
        <>
          {props.mode === "hard" ? (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6, transition: { duration: 0.5 } }}
              src={SuccessLarge}
            ></motion.img>
          ) : props.mode === "medium" ? (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6, transition: { duration: 0.5 } }}
              src={SuccessMedium}
            ></motion.img>
          ) : (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6, transition: { duration: 0.5 } }}
              src={SuccessSmall}
            ></motion.img>
          )}
        </>
      );
    }
  }
  if (
    props.revealed &&
    !props.revealed[8 - props.index]?.fields.includes(1) &&
    props.revealed[8 - props.index]?.fields[props.innerIndex] === -1
  ) {
    return (
      <>
        {props.mode === "hard" ? (
          <img src={BombLarge} alt="BombLarge" />
        ) : props.mode === "medium" ? (
          <img src={BombMedium} alt="BombMedium" />
        ) : (
          <img src={BombSmall} alt="BombSmall" />
        )}
        <div className="bomb-gif-wrapper">
          <img src={towersBombGif} alt={"towersBombGif"} />
        </div>
      </>
    );
  }
  if (
    props.revealed &&
    props.revealed[8 - props.index]?.fields[props.innerIndex] === 1
  ) {
    if (props.mode === "medium") {
      return <img src={SuccessMedium} alt="SuccessMedium" />;
    }
    if (props.mode === "hard") {
      return <img src={SuccessLarge} alt="SuccessLarge" />;
    }
    return <img src={SuccessSmall} alt="SuccessSmall" />;
  }
  if (props.mode === "medium")
    return (
      <>
        {(9 - props.index) % 3 === 0 ? (
          <img src={FieldMediumSpecial} alt="FieldMediumSpecial" />
        ) : (
          <img src={FieldMedium} alt="FieldMedium" />
        )}
      </>
    );
  if (props.mode === "hard")
    return (
      <>
        {(9 - props.index) % 3 === 0 ? (
          <img src={FieldLargeSpecial} alt="FieldLargeSpecial" />
        ) : (
          <img src={FieldLarge} alt="FieldLarge" />
        )}
      </>
    );
  return (
    <>
      {(9 - props.index) % 3 === 0 ? (
        <img src={FieldSmallSpecial} alt="FieldSmallSpecial" />
      ) : (
        <img src={FieldSmall} alt="FieldSmall" />
      )}
    </>
  );
}
