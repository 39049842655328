import styles from "./DiceAnimations.module.css";
import { motion, useAnimationControls } from "framer-motion";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as DiceRect } from "../images/dice_rect.svg";
import {
  diceLoseGif,
  diceProcessGif,
  diceWinGif,
} from "../../../components/shared/gif";
export interface IDiceAnimationsProps {
  wonTicket: boolean;
  processAnimStarted: boolean;
  setProcessAnimStarted: Dispatch<SetStateAction<boolean>>;
  wrapperWidth?: number;
  ticketData: number;
}

const DiceAnimations: FC<IDiceAnimationsProps> = ({
  processAnimStarted,
  wonTicket,
  setProcessAnimStarted,
  wrapperWidth,
  ticketData,
}) => {
  const animationControlsDice = useAnimationControls();
  const animationControlsGif = useAnimationControls();
  const animWrapperRef = useRef<HTMLInputElement>(null);
  const gifWrapperRef = useRef<HTMLInputElement>(null);

  const [animUrl, setAnimUrl] = useState<string>(diceProcessGif);
  const [endPos, setEndPos] = useState<number>(0);
  const [lastTicketPos, setLastTicketPos] = useState<number>(0);
  const startAnimation = async () => {
    setAnimUrl(diceProcessGif);
    setLastTicketPos(getTicketPos());

    await Promise.all([
      animateGifWrapper(true),
      animationControlsDice.start({ y: 0 }, { duration: 0.3 }),
    ]);
    await animationControlsDice.start(
      { x: [lastTicketPos, endPos, 0, getTicketPos()] },
      { duration: 0.5 }
    );

    setProcessAnimStarted(false);
    const promises = [animationControlsDice.start({ y: -40 }, { duration: 0.3 })]
    wonTicket ? promises.push(startWinAnim()) : promises.push(startLoseAnim());
    await Promise.all(promises);
  };

  const startWinAnim = async () => {
    setAnimUrl(diceWinGif);
    await animateGifWrapper();
  };

  const startLoseAnim = async () => {
    setAnimUrl(diceLoseGif);
    await animateGifWrapper();
  };

  const animateGifWrapper = async (restart = false) => {
    await animationControlsGif.start(
      { y: restart ? 0 : 65 },
      { duration: 0.3 }
    );
  };

  const getTicketPos = () => {
    return wrapperWidth ? (ticketData * wrapperWidth) / 100 - 40 : 0;
  };

  useEffect(() => {
    if (wrapperWidth && animWrapperRef.current?.offsetWidth) {
      setEndPos(wrapperWidth - animWrapperRef.current?.offsetWidth / 2);
    }
  }, [wrapperWidth, animWrapperRef.current?.offsetWidth]);

  useEffect(() => {
    if (processAnimStarted && ticketData) {
      startAnimation();
    }
  }, [processAnimStarted, ticketData]);
  return (
    <motion.div
      className={styles.diceAnimWrapper}
      initial={{ x: -40 }}
      animate={animationControlsDice}
      ref={animWrapperRef}
    >
      <DiceRect className={styles.diceReact} />
      {!processAnimStarted && ticketData && (
        <span
          className={`${styles.ticketData} ${
            wonTicket ? styles.ticketDataWin : styles.ticketDataLose
          }`}
        >
          {ticketData}
        </span>
      )}
      <motion.div
        ref={gifWrapperRef}
        animate={animationControlsGif}
        initial={{
          x: -20,
          y: 0,
        }}
        className={styles.diceAnimGifWrapper}
      >
        <img src={animUrl} alt="gif" />
      </motion.div>
    </motion.div>
  );
};

export default DiceAnimations;
