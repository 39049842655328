import {useContext, useState} from "react";
import {LinesTypes, Mode} from "../../types";
import styles from "./BetActions.module.css";
import DimensionsContext from "../../../../context/dimensions/dimensions";
import {HttpContext} from "../../../../context/http/http";
import {currencyIcons} from "../../../../components/shared/svg/svg";

interface PlinkoBetActions {
    onRunBet: (betValue: number) => void;
    onChangeLines: (lines: LinesTypes) => void;
    onChangeRisk: (risk: Mode) => void;
    balance: number;
    isPlaying: boolean;
}

export function BetActions({
                               onRunBet,
                               onChangeLines,
                               onChangeRisk,
                               balance,
                               isPlaying
                           }: PlinkoBetActions) {

    const { getBalance, hasBalance, exchangeRate, currency, decimalsByCurrency} = useContext(HttpContext);

    const {x} = useContext(DimensionsContext);
    const initialBet = 0.000;
    const [betValue, setBetValue] = useState(0.00);
    const [betInput, setBetInput] = useState(initialBet.toFixed(decimalsByCurrency[currency]));  // For input handling
    const linesOptions: number[] = [8, 10, 12, 14, 16];

    const [currIndex, setCurrIndex] = useState(2);
    const [currIndex2, setCurrIndex2] = useState(1);

    const currencyIcon = currencyIcons[currency];

    function handleChangeLines(value: number) {
        onChangeLines(Number(value) as LinesTypes);
    }

    function handleChangeRisk(value: string) {
        onChangeRisk(value as Mode);
    }

    function truncateToDecimals(num: any, dec: any) {
        const numStr = num.toString();
        const index = numStr.indexOf('.');
        if (index === -1) return numStr; // No decimals to truncate
        const truncated = numStr.slice(0, index + dec + 1); // Slice the string to keep only the specified number of decimals
        return truncated;
    }

    const betOperation = (operation: string) => {
        const decimals = decimalsByCurrency[currency];

        if (operation === "multiple" && !hasBalance(betValue * 2)) {
            const balance = getBalance();
            setBetValue(balance);
            setBetInput(truncateToDecimals(balance, decimals));
            return;
        }

        if (operation === "multiple") {
            const newBet = betValue * 2;
            setBetValue(newBet);
            setBetInput(truncateToDecimals(newBet, decimals));
        } else if (operation === "division") {
            const newBet = betValue / 2;
            setBetValue(newBet);
            setBetInput(truncateToDecimals(newBet, decimals));
        } else {
            const balance = getBalance();
            setBetValue(balance);
            setBetInput(truncateToDecimals(balance, decimals));
        }
    };

    const validateBet = (value: string) => {
        const regex = /^(0|[1-9]\d*)?(\.\d*)?$/;

        if (value === "" || regex.test(value)) {
            setBetInput(value); // Update the input field directly
        }
    };

    const handleBlur = (value: string) => {
        let finalValue = value.replace(/,/g, '.').replace(/\.$/, "");
        if (!finalValue) {
            setBetInput("0.0000000");
            setBetValue(0);
        } else {
            const finalRegex = /^(0|[1-9]\d*)(\.\d+)?$/;
            if (finalRegex.test(finalValue)) {
                finalValue = parseFloat(finalValue).toFixed(decimalsByCurrency[currency]);
                setBetInput(finalValue);
                setBetValue(parseFloat(finalValue));
            } else {
                setBetInput("0.0000000");
                setBetValue(0);
            }
        }
    };

    const formatBalance = (amount: number) => {
        const userLocale = navigator.language;
        const formatter = new Intl.NumberFormat(userLocale, {
            style: "currency",
            currency: "USD",
        });
        return formatter.format(amount);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.betAmountContainer}>
                <h3>Bet Amount</h3>
                <p className={styles.balanceAmount}>{formatBalance(betValue * exchangeRate)}</p>
            </div>
            <div className={styles.inputWrapper}>
                <img src={currencyIcon} alt="currency"/>
                <input
                    className={styles.inputAd}
                    value={betInput}
                    onChange={(e) => validateBet(e.target.value)}
                    onBlur={(e) => handleBlur(e.target.value)}
                    type="text"
                />
                <div className={styles.btnInputWrapper}>
                    <button
                        onClick={() => betOperation("division")}
                        className={styles.btnInput}
                        disabled={isPlaying}
                    >
                        <p>1/2</p>
                    </button>
                    <button
                        onClick={() => betOperation("multiple")}
                        className={styles.btnInput}
                        disabled={isPlaying}
                    >
                        <p>2x</p>
                    </button>
                    <button
                        onClick={() => betOperation("max")}
                        className={styles.btnInput}
                        disabled={isPlaying}
                    >
                        <p>Max</p>
                    </button>
                </div>
            </div>
            {x <= 900 ? (
                <button
                    style={{marginTop: 30, width: "100%"}}
                    onClick={() => onRunBet(betValue)}
                    className={styles.primaryBtn}
                >
                    <p>Place Bet</p>
                </button>
            ) : null}
            <h3 style={{marginTop: 20}}>Risk</h3>
            <div
                style={{gridTemplateColumns: "repeat(3, 1fr)"}}
                className={styles.btnWrapper}
            >
                {["low", "medium", "high"].map((item, index) => {
                    return (
                        <button
                            key={index}
                            style={{maxWidth: "82px"}}
                            className={
                                currIndex === index ? styles.rowBtnActive : styles.rowBtn
                            }
                            onClick={() => {
                                setCurrIndex(index);
                                handleChangeRisk(item);
                            }}
                            disabled={isPlaying}
                        >
                            {item}
                        </button>
                    );
                })}
            </div>
            <h3 style={{marginTop: 20}}>Rows</h3>
            <div className={styles.btnWrapper}>
                {linesOptions.map((item, index) => {
                    return (
                        <button
                            key={index}
                            className={
                                currIndex2 === index ? styles.rowBtnActive : styles.rowBtn
                            }
                            onClick={() => {
                                setCurrIndex2(index);
                                handleChangeLines(item);
                            }}
                            disabled={isPlaying}
                        >
                            {item}
                        </button>
                    );
                })}
            </div>
            {x > 900 ? (
                <button
                    style={{marginTop: 30, width: "100%"}}
                    onClick={() => onRunBet(betValue)}
                    className={styles.primaryBtn}
                >
                    <p>Place Bet</p>
                </button>
            ) : null}
        </div>
    );
}
