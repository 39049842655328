import styles from "./Square.module.css";
import {SquareSVG, OpenedSquare} from "./SquareSVG";
import bomb from "../../images/bomb.svg";
import {towersBombGif} from "../../../../components/shared/gif";
import {useEffect, useState} from "react";

type CubPropType = {
    isChecked: boolean;
    isMine: boolean;
    position: number;
    onClick: () => void;
    disabled: boolean;
    showAllSquares: boolean;
    isLoading: boolean; // Add this line
};

export const Square = ({
                           isChecked,
                           isMine,
                           position,
                           onClick,
                           disabled,
                           showAllSquares,
                           isLoading, // Make sure this prop is passed to the component
                       }: CubPropType) => {
    const svgClass = isLoading ? styles.pulsingSVG : '';

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <button disabled={disabled || isLoading}
                onClick={onClick} className={styles.wrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
        >
            {showAllSquares ? (
                <OpenedSquare
                    showAllSquares={showAllSquares}
                    isMine={isMine}
                    size={"100%"}
                />
            ) : isMine && isChecked ? (
                <>
                    <img src={bomb} alt="bomb"/>
                    <div className={styles.bombgif}>
                        <img src={towersBombGif} alt="towersBombGif"/>
                    </div>
                </>
            ) : !isMine && isChecked ? (
                <OpenedSquare
                    showAllSquares={showAllSquares}
                    isMine={isMine}
                    size={"100%"}
                />
            ) : (
                <SquareSVG size={"100%"} position={position}
                           isChecked={isChecked}
                           isHovered={isHovered}
                           className={svgClass}
                />
            )}
        </button>
    );
};