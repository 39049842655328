// multiplierImages
import multiplier020 from "../images/multipliers/020.svg";
import multiplier03 from "../images/multipliers/03.svg";
import multiplier04 from "../images/multipliers/04.svg";
import multiplier05 from "../images/multipliers/05.svg";
import multiplier06 from "../images/multipliers/06.svg";
import multiplier07 from "../images/multipliers/07.svg";
import multiplier09 from "../images/multipliers/09.svg";
import multiplier1 from "../images/multipliers/1.svg";
import multiplier11x from "../images/multipliers/1-1.svg";
import multiplier12x from "../images/multipliers/1-2.svg";
import multiplier13x from "../images/multipliers/1-3.svg";
import multiplier14x from "../images/multipliers/1-4.svg";
import multiplier15 from "../images/multipliers/15.svg";
import multiplier16 from "../images/multipliers/16.svg";
import multiplier19 from "../images/multipliers/19.svg";
import multiplier2 from "../images/multipliers/2.svg";
import multiplier21 from "../images/multipliers/21.svg";
import multiplier3 from "../images/multipliers/3.svg";
import multiplier4 from "../images/multipliers/4.svg";
import multiplier5 from "../images/multipliers/5.svg";
import multiplier56x from "../images/multipliers/5-6.svg";
import multiplier7 from "../images/multipliers/7.svg";
import multiplier71 from "../images/multipliers/71.svg";
import multiplier81 from "../images/multipliers/81.svg";
import multiplier89 from "../images/multipliers/89.svg";
import multiplier9 from "../images/multipliers/9.svg";
import multiplier10 from "../images/multipliers/10.svg";
import multiplier11 from "../images/multipliers/11.svg";
import multiplier13 from "../images/multipliers/13.svg";
import multiplier150 from "../images/multipliers/150.svg";
import multiplier160 from "../images/multipliers/160.svg";
import multiplier18 from "../images/multipliers/18.svg";
import multiplier22 from "../images/multipliers/22.svg";
import multiplier24 from "../images/multipliers/24.svg";
import multiplier26 from "../images/multipliers/26.svg";
import multiplier29 from "../images/multipliers/29.svg";
import multiplier33 from "../images/multipliers/33.svg";
import multiplier41 from "../images/multipliers/41.svg";
import multiplier56 from "../images/multipliers/56.svg";
import multiplier58 from "../images/multipliers/58.svg";
import multiplier76 from "../images/multipliers/76.svg";
import multiplier110 from "../images/multipliers/110.svg";
import multiplier130 from "../images/multipliers/130.svg";
import multiplier170 from "../images/multipliers/170.svg";
import multiplier420 from "../images/multipliers/420.svg";
import multiplier1000 from "../images/multipliers/1000.svg";

import { ReactComponent as svgmultiplier020 } from "../images/multipliers/020.svg";
import { ReactComponent as svgmultiplier03 } from "../images/multipliers/03.svg";
import { ReactComponent as svgmultiplier04 } from "../images/multipliers/04.svg";
import { ReactComponent as svgmultiplier05 } from "../images/multipliers/05.svg";
import { ReactComponent as svgmultiplier06 } from "../images/multipliers/06.svg";
import { ReactComponent as svgmultiplier07 } from "../images/multipliers/07.svg";
import { ReactComponent as svgmultiplier09 } from "../images/multipliers/09.svg";
import { ReactComponent as svgmultiplier1 } from "../images/multipliers/1.svg";
import { ReactComponent as svgmultiplier11x } from "../images/multipliers/1-1.svg";
import { ReactComponent as svgmultiplier12x } from "../images/multipliers/1-2.svg";
import { ReactComponent as svgmultiplier13x } from "../images/multipliers/1-3.svg";
import { ReactComponent as svgmultiplier14x } from "../images/multipliers/1-4.svg";
import { ReactComponent as svgmultiplier15 } from "../images/multipliers/15.svg";
import { ReactComponent as svgmultiplier16 } from "../images/multipliers/16.svg";
import { ReactComponent as svgmultiplier19 } from "../images/multipliers/19.svg";
import { ReactComponent as svgmultiplier2 } from "../images/multipliers/2.svg";
import { ReactComponent as svgmultiplier21 } from "../images/multipliers/21.svg";
import { ReactComponent as svgmultiplier3 } from "../images/multipliers/3.svg";
import { ReactComponent as svgmultiplier4 } from "../images/multipliers/4.svg";
import { ReactComponent as svgmultiplier5 } from "../images/multipliers/5.svg";
import { ReactComponent as svgmultiplier56x } from "../images/multipliers/5-6.svg";
import { ReactComponent as svgmultiplier7 } from "../images/multipliers/7.svg";
import { ReactComponent as svgmultiplier71 } from "../images/multipliers/71.svg";
import { ReactComponent as svgmultiplier81 } from "../images/multipliers/81.svg";
import { ReactComponent as svgmultiplier89 } from "../images/multipliers/89.svg";
import { ReactComponent as svgmultiplier9 } from "../images/multipliers/9.svg";
import { ReactComponent as svgmultiplier10 } from "../images/multipliers/10.svg";
import { ReactComponent as svgmultiplier11 } from "../images/multipliers/11.svg";
import { ReactComponent as svgmultiplier13 } from "../images/multipliers/13.svg";
import { ReactComponent as svgmultiplier150 } from "../images/multipliers/150.svg";
import { ReactComponent as svgmultiplier160 } from "../images/multipliers/160.svg";
import { ReactComponent as svgmultiplier18 } from "../images/multipliers/18.svg";
import { ReactComponent as svgmultiplier22 } from "../images/multipliers/22.svg";
import { ReactComponent as svgmultiplier24 } from "../images/multipliers/24.svg";
import { ReactComponent as svgmultiplier26 } from "../images/multipliers/26.svg";
import { ReactComponent as svgmultiplier29 } from "../images/multipliers/29.svg";
import { ReactComponent as svgmultiplier33 } from "../images/multipliers/33.svg";
import { ReactComponent as svgmultiplier41 } from "../images/multipliers/41.svg";
import { ReactComponent as svgmultiplier56 } from "../images/multipliers/56.svg";
import { ReactComponent as svgmultiplier58 } from "../images/multipliers/58.svg";
import { ReactComponent as svgmultiplier76 } from "../images/multipliers/76.svg";
import { ReactComponent as svgmultiplier110 } from "../images/multipliers/110.svg";
import { ReactComponent as svgmultiplier130 } from "../images/multipliers/130.svg";
import { ReactComponent as svgmultiplier170 } from "../images/multipliers/170.svg";
import { ReactComponent as svgmultiplier420 } from "../images/multipliers/420.svg";
import { ReactComponent as svgmultiplier1000 } from "../images/multipliers/1000.svg";

import lowmultiplieraudio from "../sounds/LowMultiplier.mp3";
import mediummultiplieraudio from "../sounds/MediumMultiplier.mp3";
import highmultiplieraudio from "../sounds/HighMultiplier.mp3";

import { Howl } from "howler";
import { LinesType, LinesTypes, Mode, MultiplierType } from "../types";

const HighAudio = new Howl({
  src: [highmultiplieraudio],
  volume: 0.2,
  html5: true,
});
const MediumAudio = new Howl({
  src: [mediummultiplieraudio],
  volume: 0.2,
  html5: true,
});
const LowAudio = new Howl({
  src: [lowmultiplieraudio],
  volume: 0.2,
  html5: true,
});

export const getMultiplierSound = (multiplier: number) => {
  if (multiplier >= 25) {
    return HighAudio;
  } else if (multiplier >= 5) {
    return MediumAudio;
  } else {
    return LowAudio;
  }
};

const multipliers = {
  0.2: {
    label: "block-0.2",
    img: multiplier020,
    svg: svgmultiplier020,
  },
  0.3: {
    label: "block-0.3",
    img: multiplier03,
    svg: svgmultiplier03,
  },
  0.4: {
    label: "block-0.4",
    img: multiplier04,
    svg: svgmultiplier04,
  },
  0.5: {
    label: "block-0.5",
    img: multiplier05,
    svg: svgmultiplier05,
  },
  0.6: {
    label: "block-0.6",
    img: multiplier06,
    svg: svgmultiplier06,
  },
  0.7: {
    label: "block-0.7",
    img: multiplier07,
    svg: svgmultiplier07,
  },
  0.9: {
    label: "block-0.9",
    img: multiplier09,
    svg: svgmultiplier09,
  },
  1: {
    label: "block-1",
    img: multiplier1,
    svg: svgmultiplier1,
  },
  1.1: {
    label: "block-1.1",
    img: multiplier11x,
    svg: svgmultiplier11x,
  },
  1.2: {
    label: "block-1.2",
    img: multiplier12x,
    svg: svgmultiplier12x,
  },
  1.3: {
    label: "block-1.3",
    img: multiplier13x,
    svg: svgmultiplier13x,
  },
  1.4: {
    label: "block-1.4",
    img: multiplier14x,
    svg: svgmultiplier14x,
  },
  1.5: {
    label: "block-1.5",
    img: multiplier15,
    svg: svgmultiplier15,
  },
  1.6: {
    label: "block-1.6",
    img: multiplier16,
    svg: svgmultiplier16,
  },
  1.9: {
    label: "block-1.9",
    img: multiplier19,
    svg: svgmultiplier19,
  },
  2: {
    label: "block-2",
    img: multiplier2,
    svg: svgmultiplier2,
  },
  2.1: {
    label: "block-2.1",
    img: multiplier21,
    svg: svgmultiplier21,
  },
  3: {
    label: "block-3",
    img: multiplier3,
    svg: svgmultiplier3,
  },
  4: {
    label: "block-4",
    img: multiplier4,
    svg: svgmultiplier4,
  },
  5: {
    label: "block-5",
    img: multiplier5,
    svg: svgmultiplier5,
  },
  5.6: {
    label: "block-5.6",
    img: multiplier56x,
    svg: svgmultiplier56x,
  },
  7: {
    label: "block-7",
    img: multiplier7,
    svg: svgmultiplier7,
  },
  7.1: {
    label: "block-7.1",
    img: multiplier71,
    svg: svgmultiplier71,
  },
  8.1: {
    label: "block-8.1",
    img: multiplier81,
    svg: svgmultiplier81,
  },
  8.9: {
    label: "block-8.9",
    img: multiplier89,
    svg: svgmultiplier89,
  },
  9: {
    label: "block-9",
    img: multiplier9,
    svg: svgmultiplier9,
  },
  10: {
    label: "block-10",
    img: multiplier10,
    svg: svgmultiplier10,
  },
  11: {
    label: "block-11",
    img: multiplier11,
    svg: svgmultiplier11,
  },
  13: {
    label: "block-13",
    img: multiplier13,
    svg: svgmultiplier13,
  },
  15: {
    label: "block-15",
    img: multiplier150,
    svg: svgmultiplier150,
  },
  16: {
    label: "block-16",
    img: multiplier160,
    svg: svgmultiplier160,
  },
  18: {
    label: "block-18",
    img: multiplier18,
    svg: svgmultiplier18,
  },
  22: {
    label: "block-22",
    img: multiplier22,
    svg: svgmultiplier22,
  },
  24: {
    label: "block-24",
    img: multiplier24,
    svg: svgmultiplier24,
  },
  26: {
    label: "block-26",
    img: multiplier26,
    svg: svgmultiplier26,
  },
  29: {
    label: "block-29",
    img: multiplier29,
    svg: svgmultiplier29,
  },
  33: {
    label: "block-33",
    img: multiplier33,
    svg: svgmultiplier33,
  },
  41: {
    label: "block-41",
    img: multiplier41,
    svg: svgmultiplier41,
  },
  56: {
    label: "block-56",
    img: multiplier56,
    svg: svgmultiplier56,
  },
  58: {
    label: "block-58",
    img: multiplier58,
    svg: svgmultiplier58,
  },
  76: {
    label: "block-76",
    img: multiplier76,
    svg: svgmultiplier76,
  },
  110: {
    label: "block-110",
    img: multiplier110,
    svg: svgmultiplier110,
  },
  130: {
    label: "block-130",
    img: multiplier130,
    svg: svgmultiplier130,
  },
  170: {
    label: "block-170",
    img: multiplier170,
    svg: svgmultiplier170,
  },
  420: {
    label: "block-420",
    img: multiplier420,
    svg: svgmultiplier420,
  },
  1000: {
    label: "block-1000",
    img: multiplier1000,
    svg: svgmultiplier1000,
  },
} as const;

export type MultipliersType = keyof typeof multipliers;

export function getMultiplier(value: MultipliersType): MultiplierType {
  return multipliers[value];
}

export const low8Lines = [
  getMultiplier(5.6),
  getMultiplier(2.1),
  getMultiplier(1.1),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.1),
  getMultiplier(2.1),
  getMultiplier(5.6),
];

export const low10Lines = [
  getMultiplier(8.9),
  getMultiplier(3),
  getMultiplier(1.4),
  getMultiplier(1.1),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.1),
  getMultiplier(1.4),
  getMultiplier(3),
  getMultiplier(8.9),
];
export const low12Lines = [
  getMultiplier(10),
  getMultiplier(3),
  getMultiplier(1.6),
  getMultiplier(1.4),
  getMultiplier(1.1),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.1),
  getMultiplier(1.4),
  getMultiplier(1.6),
  getMultiplier(3),
  getMultiplier(10),
];
export const low14Lines = [
  getMultiplier(7.1),
  getMultiplier(4),
  getMultiplier(1.9),
  getMultiplier(1.4),
  getMultiplier(1.3),
  getMultiplier(1.1),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.1),
  getMultiplier(1.3),
  getMultiplier(1.4),
  getMultiplier(1.9),
  getMultiplier(4),
  getMultiplier(7.1),
];
export const low16Lines = [
  getMultiplier(16.0),
  getMultiplier(9),
  getMultiplier(2),
  getMultiplier(1.4),
  getMultiplier(1.4),
  getMultiplier(1.2),
  getMultiplier(1.1),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.1),
  getMultiplier(1.2),
  getMultiplier(1.4),
  getMultiplier(1.4),
  getMultiplier(2),
  getMultiplier(9),
  getMultiplier(16.0),
];

export const medium8Lines = [
  getMultiplier(13),
  getMultiplier(3),
  getMultiplier(1.3),
  getMultiplier(0.7),
  getMultiplier(0.4),
  getMultiplier(0.7),
  getMultiplier(1.3),
  getMultiplier(3),
  getMultiplier(13),
];

export const medium10Lines = [
  getMultiplier(22),
  getMultiplier(5),
  getMultiplier(2),
  getMultiplier(1.4),
  getMultiplier(0.6),
  getMultiplier(0.4),
  getMultiplier(0.6),
  getMultiplier(1.4),
  getMultiplier(2),
  getMultiplier(5),
  getMultiplier(22),
];
export const medium12Lines = [
  getMultiplier(33),
  getMultiplier(11),
  getMultiplier(4),
  getMultiplier(2),
  getMultiplier(1.1),
  getMultiplier(0.6),
  getMultiplier(0.3),
  getMultiplier(0.6),
  getMultiplier(1.1),
  getMultiplier(2),
  getMultiplier(4),
  getMultiplier(11),
  getMultiplier(33),
];
export const medium14Lines = [
  getMultiplier(58),
  getMultiplier(15.0),
  getMultiplier(7),
  getMultiplier(4),
  getMultiplier(1.9),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(0.2),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.9),
  getMultiplier(4),
  getMultiplier(7),
  getMultiplier(15.0),
  getMultiplier(58),
];
export const medium16Lines = [
  getMultiplier(110),
  getMultiplier(41),
  getMultiplier(10),
  getMultiplier(5),
  getMultiplier(3),
  getMultiplier(1.5),
  getMultiplier(1),
  getMultiplier(0.5),
  getMultiplier(0.3),
  getMultiplier(0.5),
  getMultiplier(1),
  getMultiplier(1.5),
  getMultiplier(3),
  getMultiplier(5),
  getMultiplier(10),
  getMultiplier(41),
  getMultiplier(110),
];

export const high8Lines = [
  getMultiplier(29),
  getMultiplier(4),
  getMultiplier(1.5),
  getMultiplier(0.3),
  getMultiplier(0.2),
  getMultiplier(0.3),
  getMultiplier(1.5),
  getMultiplier(4),
  getMultiplier(29),
];

export const high10Lines = [
  getMultiplier(76),
  getMultiplier(10),
  getMultiplier(3),
  getMultiplier(0.9),
  getMultiplier(0.3),
  getMultiplier(0.2),
  getMultiplier(0.3),
  getMultiplier(0.9),
  getMultiplier(3),
  getMultiplier(10),
  getMultiplier(76),
];
export const high12Lines = [
  getMultiplier(170),
  getMultiplier(24),
  getMultiplier(8.1),
  getMultiplier(2),
  getMultiplier(0.7),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.7),
  getMultiplier(2),
  getMultiplier(8.1),
  getMultiplier(24),
  getMultiplier(170),
];
export const high14Lines = [
  getMultiplier(420),
  getMultiplier(56),
  getMultiplier(18),
  getMultiplier(5),
  getMultiplier(1.9),
  getMultiplier(0.3),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.3),
  getMultiplier(1.9),
  getMultiplier(5),
  getMultiplier(18),
  getMultiplier(56),
  getMultiplier(420),
];
export const high16Lines = [
  getMultiplier(1000),
  getMultiplier(130),
  getMultiplier(26),
  getMultiplier(9),
  getMultiplier(4),
  getMultiplier(2),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(0.2),
  getMultiplier(2),
  getMultiplier(4),
  getMultiplier(9),
  getMultiplier(26),
  getMultiplier(130),
  getMultiplier(1000),
];

export const multiplyBlocksByLinesQnt = {
  low8: low8Lines,
  medium8: medium8Lines,
  high8: high8Lines,
  low10: low10Lines,
  medium10: medium10Lines,
  high10: high10Lines,
  low12: low12Lines,
  medium12: medium12Lines,
  high12: high12Lines,
  low14: low14Lines,
  medium14: medium14Lines,
  high14: high14Lines,
  low16: low16Lines,
  medium16: medium16Lines,
  high16: high16Lines,
};

export function getMultiplierByLinesQnt(
  value: LinesTypes,
  mode: Mode
): MultiplierType[] {
  const query: LinesType = `${mode}${value}`;
  return multiplyBlocksByLinesQnt[query];
}
