import clickaudio from "../../components/shared/sounds/Click.mp3";
import {
    Bodies,
    Body,
    Composite,
    Engine,
    Events,
    IEventCollision,
    World,
} from "matter-js";
import {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import {LinesTypes, MultiplierValues, Mode} from "./types";
import {BetActions} from "./components/BetActions/BetActions";
import {PlinkoGameBody} from "./components/GameBody";
import {config} from "./config";
import {
    getMultiplier,
    getMultiplierByLinesQnt,
    getMultiplierSound,
} from "./config/multipliers";
import plinkopin from "./images/plinkopin.svg";
import plinkoball from "./images/plinkoball.svg";
import {useSnackbar} from "../../hooks/useSnackbar";
import DimensionsContext from "../../context/dimensions/dimensions";
import "./PlinkoGame.css";
import Multipliers from "./components/Multipliers/Multipliers";
import {PlinkoContext} from "../../context/plinko/plinko";
import spriteFile from "./images/plinkosprites.png";
import {Howl} from "howler";
import {HttpContext} from "../../context/http/http";

var ballSound = new Howl({
    src: [clickaudio],
    volume: 0.5,
    html5: true,
});

export function PlinkoGame() {
    const {notify} = useSnackbar();
    const {x} = useContext(DimensionsContext);
    const {gameData, setGameData, balance, setBalance} = useContext(PlinkoContext);
    const {hasBalance, sendUpdateToParent, getPlinkoGameState, plinkoGame} = useContext(HttpContext);

    const [renderer, setRenderer] = useState<CustomRender>();
    const [engine, setEngine] = useState<Engine>(Engine.create());
    const [engineInterval, setEngineInterval] = useState<any>();
    const [placeBetButtonDisabled, setPlaceBetButtonDisabled] = useState(false);

    const [lines, setLines] = useState<LinesTypes>(10);
    const [mode, setMode] = useState<Mode>("high");
    const [lastMultipliers, setLastMultipliers] = useState<any[]>([]);

    const [ballCount, setBallCount] = useState(0);  // State to track number of balls

    const isPlaying = ballCount > 0;  // isPlaying is true if there are balls in the world


    const {
        pins: pinsConfig,
        ball: ballConfig,
        engine: engineConfig,
        world: worldConfig,
    } = config;

    const worldWidth: number = worldConfig.width;

    const worldHeight: number = worldConfig.height;

    const [settings, setSettings] = useState({
        htmlWidth: worldWidth,
        htmlHeight: worldHeight,
        width: worldWidth * window.devicePixelRatio,
        height: worldHeight * window.devicePixelRatio,
        pinSize: pinsConfig.pinSize,
        pinGap: pinsConfig.pinGap,
        ratio: window.devicePixelRatio,
        constant: 1,
    });

    const testImage = new Image();

    useLayoutEffect(() => {
        testImage.onload = function () {
            const element = document.getElementById("plinko");
            const render = new CustomRender(element, settings);
            setRenderer(render);
        };

        testImage.src = spriteFile;
        // eslint-disable-next-line
        getPlinkoGameState(setBalance).finally(() => {
        });
    }, []);

    useEffect(() => {
        if (gameData && gameData?.dropValue) {
            const gameDataCopy = JSON.parse(JSON.stringify(gameData));
            setGameData(null);
            addBall(gameDataCopy);
        }
        // eslint-disable-next-line
    }, [gameData]);

    class CustomRender {
        element: any;
        canvas: any;
        ctx: any;
        canvasSettings: any;
        engine: Engine;
        pinSprite: any;
        ballSprite: any;
        multiplierSprites: any;
        fpsInterval: any;
        timestamp: number;

        constructor(element: any, canvasSettings: any) {
            this.canvasSettings = canvasSettings;
            this.element = element;
            this.engine = engine;

            this.canvas = document.createElement("canvas");
            this.canvas.width = canvasSettings.width;
            this.canvas.height = canvasSettings.height;
            this.canvas.style.width = canvasSettings.htmlWidth + "px";
            this.canvas.style.height = canvasSettings.htmlHeight + "px";
            this.ctx = this.canvas.getContext("2d");
            this.ctx.imageSmoothingEnabled = true;
            element.appendChild(this.canvas);

            this.timestamp = Date.now();
            this.multiplierSprites = {};
            this.initSprites();
            this.startAnimating(120);
        }

        initSprites() {
            const pinImage = new Image();
            pinImage.src = plinkopin;
            const ballImage = new Image();
            ballImage.src = plinkoball;
            this.pinSprite = pinImage;
            this.ballSprite = ballImage;
        }

        initMultiplierSprites(engine: Engine) {
            const multipliers = Composite.allBodies(engine.world).filter(
                (body) =>
                    body.label.indexOf("block") > -1 && body.render.visible === true
            );
            multipliers.forEach((multiplier) => {
                const multImage = new Image();
                multImage.src = multiplier.render.sprite?.texture!;
                this.multiplierSprites[multiplier.label.substring(8)] = multImage;
            });
        }

        updateSettings(engine: Engine, canvasSettings: any) {
            this.canvasSettings = canvasSettings;
            this.canvas.width = canvasSettings.width;
            this.canvas.height = canvasSettings.height;
            this.canvas.style.width = canvasSettings.htmlWidth + "px";
            this.canvas.style.height = canvasSettings.htmlHeight + "px";
            this.engine = engine;
            this.initMultiplierSprites(engine);
        }

        startAnimating(fps: number) {
            this.fpsInterval = 1000 / fps;
            this.timestamp = Date.now();
            this.loop();
        }

        loop() {
            const now = Date.now();
            const elapsed = now - Number(this.timestamp);

            if (elapsed > this.fpsInterval) {
                this.timestamp = now - (elapsed % this.fpsInterval);
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                const bodies = Composite.allBodies(this.engine.world);
                const pins = bodies.filter((body) => body.label.indexOf("pin") > -1);
                const multipliers = bodies.filter(
                    (body) =>
                        body.label.indexOf("block") > -1 && body.render.visible === true
                );
                const balls = bodies.filter((body) => body.label.indexOf("ball") > -1);
                // console.log(bodies);

                // Draw Pins
                for (const pin of pins) {
                    const newPinWidth = this.pinSprite.width * pin.render.sprite?.xScale!;
                    const newPinHeight =
                        this.pinSprite.height * pin.render.sprite?.yScale!;
                    this.ctx.drawImage(
                        this.pinSprite,
                        0,
                        0,
                        this.pinSprite.width,
                        this.pinSprite.height,
                        (pin.position.x -
                            // @ts-ignore
                            newPinWidth * pin.render.sprite?.xOffset!) *
                        this.canvasSettings.ratio,
                        (pin.position.y -
                            // @ts-ignore
                            newPinHeight * pin.render.sprite?.yOffset!) *
                        this.canvasSettings.ratio,
                        newPinWidth * this.canvasSettings.ratio,
                        newPinHeight * this.canvasSettings.ratio
                    );
                }

                // Draw Balls
                for (const ball of balls) {
                    if (!ball.render.visible) continue;
                    const newBallWidth =
                        this.ballSprite.width * ball.render.sprite?.xScale!;
                    const newBallHeight =
                        this.ballSprite.height * ball.render.sprite?.yScale!;
                    this.ctx.drawImage(
                        this.ballSprite,
                        0,
                        0,
                        this.ballSprite.width,
                        this.ballSprite.height,
                        (ball.position.x -
                            // @ts-ignore
                            newBallWidth * ball.render.sprite?.xOffset!) *
                        this.canvasSettings.ratio,
                        (ball.position.y -
                            // @ts-ignore
                            newBallHeight * ball.render.sprite?.yOffset!) *
                        this.canvasSettings.ratio,
                        newBallWidth * this.canvasSettings.ratio,
                        newBallHeight * this.canvasSettings.ratio
                    );
                }

                // Draw Multipliers
                for (const multiplier of multipliers) {
                    if (
                        !this.multiplierSprites.hasOwnProperty(
                            multiplier.label.substring(8)
                        )
                    ) {
                        this.initMultiplierSprites(this.engine);
                    }
                    const multiplierImage =
                        this.multiplierSprites[multiplier.label.substring(8)];
                    const newMultiplierWidth =
                        multiplierImage.width * multiplier.render.sprite?.xScale!;
                    const newMultiplierHeight =
                        multiplierImage.height * multiplier.render.sprite?.yScale!;
                    this.ctx.drawImage(
                        multiplierImage,
                        0,
                        0,
                        multiplierImage.width,
                        multiplierImage.height,
                        (multiplier.position.x -
                            newMultiplierWidth *
                            // @ts-ignore
                            multiplier.render.sprite?.xOffset!) *
                        this.canvasSettings.ratio,
                        (multiplier.position.y -
                            newMultiplierHeight *
                            // @ts-ignore
                            multiplier.render.sprite?.yOffset!) *
                        this.canvasSettings.ratio,
                        newMultiplierWidth * this.canvasSettings.ratio,
                        newMultiplierHeight * this.canvasSettings.ratio
                    );
                }
            }
            window.requestAnimationFrame(() => {
                this.loop();
            });
        }
    }

    useEffect(() => {
        if (x > 530) {
            setSettings((prev) => ({
                ...prev,
                htmlWidth: worldWidth,
                htmlHeight: worldHeight,
                width: worldWidth * settings.ratio,
                height: worldHeight * settings.ratio,
                pinSize: pinsConfig.pinSize,
                pinGap: pinsConfig.pinGap,
                constant: 1,
            }));
        } else {
            setSettings((prev) => ({
                ...prev,
                htmlWidth: worldWidth * 0.7,
                htmlHeight: worldHeight * 0.7,
                width: worldWidth * 0.7 * settings.ratio,
                height: worldHeight * 0.7 * settings.ratio,
                pinSize: pinsConfig.pinSize * 0.7,
                pinGap: pinsConfig.pinGap * 0.7,
                constant: 0.7,
            }));
        }
        // eslint-disable-next-line
    }, [x]);

    useEffect(() => {
        //@ts-ignore
        const createEngine = Engine.create({
            velocityIterations: 4,
            positionIterations: 6,
            constraintIterations: 2,
        });
        setEngine(createEngine);
        engine.gravity.y = engineConfig.engineGravity;
        if (engineInterval) {
            clearInterval(engineInterval);
        }
        const myInterval = setInterval(() => {
            Engine.update(engine, 8);
        }, 1000 / 120);
        setEngineInterval(myInterval);
        Events.on(engine, "collisionActive", onBodyCollision);

        const pinGap = settings.pinGap * (16 / lines);
        const pinSize = settings.pinSize * (16 / lines);

        const pins: Body[] = [];

        for (let l = 0; l < lines; l++) {
            const linePins = pinsConfig.startPins + l;
            const lineWidth = linePins * pinGap;
            for (let i = 0; i < linePins; i++) {
                const pinX =
                    settings.htmlWidth / 2 - lineWidth / 2 + i * pinGap + pinGap / 2;

                const pinY =
                    settings.htmlHeight / lines + l * pinGap + 26 * (lines / 16);

                const pin = Bodies.circle(pinX, pinY, pinSize, {
                    label: `pin-${i}`,
                    render: {
                        fillStyle: "#90ACB5",
                        sprite: {
                            texture: plinkopin,
                            xScale: 0.5 * (16 / lines) * settings.constant,
                            yScale: 0.5 * (16 / lines) * settings.constant,
                        },
                    },
                    isStatic: true,
                });
                pins.push(pin);
            }
        }

        const multipliers = getMultiplierByLinesQnt(lines, mode);

        const multipliersBodies: Body[] = [];
        const staticMultipliers: Body[] = [];

        let lastMultiplierX: number =
            settings.htmlWidth / 2 - (pinGap / 2) * lines - pinGap;

        multipliers.forEach((multiplier, index) => {
            const blockSizeX = settings.constant * 24 * (16 / lines);
            const blockSizeY = settings.constant * 30 * (16 / lines);
            const multiplierBody = Bodies.rectangle(
                lastMultiplierX + pinGap,
                settings.htmlHeight / lines + lines * pinGap + 26 * (lines / 16),
                blockSizeX,
                blockSizeY,
                {
                    label: `${index}-multiplier-${multiplier.label}`,
                    isStatic: true,
                    isSensor: true,
                    render: {
                        visible: false,
                    },
                }
            );
            lastMultiplierX = multiplierBody.position.x;
            staticMultipliers.push(multiplierBody);
        });

        lastMultiplierX = settings.htmlWidth / 2 - (pinGap / 2) * lines - pinGap;

        multipliers.forEach((multiplier, index) => {
            const blockSizeX = settings.constant * 24 * (16 / lines);
            const blockSizeY = settings.constant * 30 * (16 / lines);
            const multiplierBody = Bodies.rectangle(
                lastMultiplierX + pinGap,
                settings.htmlHeight / lines + lines * pinGap + 26 * (lines / 16),
                blockSizeX,
                blockSizeY,
                {
                    label: `${index}-${multiplier.label}`,
                    isStatic: true,
                    frictionAir: 0.3,
                    render: {
                        sprite: {
                            xScale: 0.9 * (16 / lines) * settings.constant,
                            yScale: 0.9 * (16 / lines) * settings.constant,
                            texture: multiplier.img,
                        },
                    },
                }
            );
            lastMultiplierX = multiplierBody.position.x;
            multipliersBodies.push(multiplierBody);
        });

        Composite.add(engine.world, [
            ...pins,
            ...multipliersBodies,
            ...staticMultipliers,
        ]);
        renderer?.updateSettings(engine, settings);

        return () => {
            World.clear(engine.world, true);
            Engine.clear(engine);
            Composite.clear(engine.world, true);
            Events.off(engine, "collisionActive", onBodyCollision);
        };
        // eslint-disable-next-line
    }, [lines, mode, settings, renderer]);

    const addBall = useCallback(
        async (pgameData: any) => {
            ballSound.play();
            let ballX = pgameData.dropValue;

            // If is Firefox, use dropValueAlt
            if (navigator.userAgent.indexOf("Firefox") !== -1) {
                ballX = pgameData.dropValueAlt;
            }

            const ballId = new Date().getTime();

            const ball = Bodies.circle(
                ballX,
                20 * (1 / settings.constant),
                ballConfig.ballSize * (16 / lines) * settings.constant,
                {
                    restitution: 0.7,
                    friction: 0.7,
                    label: `ball-${pgameData.betValue}`,
                    id: ballId,
                    frictionAir: 0.04,
                    collisionFilter: {
                        group: -1,
                    },
                    render: {
                        fillStyle: "#72F138",
                        sprite: {
                            texture: plinkoball,
                            xScale: 0.5 * (16 / lines) * settings.constant,
                            yScale: 0.5 * (16 / lines) * settings.constant,
                        },
                    },
                    plugin: {
                        balance: pgameData.balance,
                        newBetValueCrypto: pgameData.bet_value_crypto_credit,
                        cryptocurrency: pgameData.cryptocurrency,
                    },
                    isStatic: false,
                }
            );
            Composite.add(engine.world, ball);
            setBallCount((prev) => prev + 1);  // Increment the ball count
        },
        // eslint-disable-next-line
        [lines, mode, settings, renderer]
    );

    async function bet(betValue: number) {
        if (!placeBetButtonDisabled) {
            // setPlaceBetButtonDisabled(true);
            // setTimeout(() => {
            //     setPlaceBetButtonDisabled(false);
            // }, 150);
            // if (betValue <= 0) {
            //   notify("Bet value can't be 0", "error");
            //   return;
            // }
            if (!hasBalance(betValue)) {
                notify("You don't have enough balance", "error");
                return;
            }
            plinkoGame(
                lines,
                betValue,
                mode,
                settings.constant === 0.7 ? true : false
            );
            // const gameData = await request("/api/games/plinko/game", "POST", {
            //   lines,
            //   betValue,
            //   mode,
            //   mobile: settings.constant === 0.7 ? true : false,
            // });
            // if (gameData.statusCode === 400 || gameData.statusCode === 403) {
            //   notify(gameData.msg, "error");
            //   return;
            // }
            // addBall(gameData, betValue);
        }
    }

    function onCollideWithStaticMultiplier(ball: Body, multiplier: Body) {
        ball.collisionFilter.group = 2;
        World.remove(engine.world, ball);
        setBallCount((prev) => prev - 1);  // Decrement the ball count

        const balance = ball.plugin.balance;
        const newBetValueCrypto = ball.plugin.newBetValueCrypto;
        const cryptocurrency = ball.plugin.cryptocurrency;

        const ballValue = ball.label.split("-")[1];
        const multiplierValue = +multiplier.label.split("-")[3] as MultiplierValues;
        // const newBalance = Number((+ballValue * multiplierValue).toFixed(2));

        // var sound = new Howl({
        //   src: [getMultiplierSound(multiplierValue)],
        //   volume: 0.2,
        //   html5: true,
        // });

        setBalance(balance);
        sendUpdateToParent(cryptocurrency, newBetValueCrypto, "add");

        const sound = getMultiplierSound(multiplierValue);
        sound.play();

        setLastMultipliers((prev) => prev.slice(0, 4));

        setLastMultipliers((prev) => [
            {id: ball.id, multiplier: getMultiplier(multiplierValue).svg},
            ...prev,
        ]);
    }

    function onCollideWithMultiplier(ball: Body, multiplier: Body) {
        const origPosition = {...multiplier.position};
        const origAngle = parseFloat(multiplier.angle.toString());
        ball.collisionFilter.group = 2;
        World.remove(engine.world, ball);
        if (multiplier.isStatic === true) {
            Body.setMass(multiplier, 0.01);
            Body.setStatic(multiplier, false);
            setTimeout(() => {
                Body.applyForce(
                    multiplier,
                    {
                        x: multiplier.position.x,
                        y: multiplier.position.y - ((5 * 16) / lines) * settings.constant,
                    },
                    {x: 0, y: -0.075 * ((16 / lines) * settings.constant) ** 2}
                );
                setTimeout(() => {
                    Body.setStatic(multiplier, true);
                    Body.setPosition(multiplier, origPosition);
                    Body.setAngle(multiplier, origAngle);
                }, 120);
            }, 200);
        }
    }

    function onBodyCollision(event: IEventCollision<Engine>) {
        const pairs = event.pairs;
        for (const pair of pairs) {
            const {bodyA, bodyB} = pair;
            if (bodyB.label.includes("ball") && bodyA.label.includes("multiplier")) {
                onCollideWithStaticMultiplier(bodyB, bodyA);
            } else if (
                bodyB.label.includes("ball") &&
                bodyA.label.includes("block")
            ) {
                onCollideWithMultiplier(bodyB, bodyA);
            }
        }
    }

    return (
        <div className="plinko-container">
            <div className="plinko">
                {x > 900 ? (
                    <div className="plinko-wrapper">
                        <div className="plinko-left">
                            <BetActions
                                onChangeLines={setLines}
                                onChangeRisk={setMode}
                                onRunBet={bet}
                                balance={balance}
                                isPlaying={isPlaying}
                            />
                            <Multipliers multipliers={lastMultipliers}/>
                        </div>
                        <PlinkoGameBody/>
                    </div>
                ) : (
                    <>
                        <Multipliers multipliers={lastMultipliers}/>
                        <div className="plinko-wrapper">
                            <PlinkoGameBody/>
                        </div>
                        <BetActions
                            onChangeLines={setLines}
                            onChangeRisk={setMode}
                            onRunBet={bet}
                            balance={balance}
                            isPlaying={isPlaying}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
