import React from "react";
import { IMultiplier, Mode } from "../../Games/Towers/types";

interface ITowersContext {
  gameID: number;
  setGameID: any;
  flagField: boolean;
  setFlagField: any;
  isLose: boolean;
  setIsLose: any;
  isWin: boolean;
  setIsWin: any;
  mode: Mode;
  setMode: any;
  showAllSquares: boolean;
  setShowAllSquares: any;
  revealedTowers: any;
  setRevealedTowers: any;
  revealed: any;
  setRevealed: any;
  round: number;
  setRound: any;
  actualBet: number;
  setActualBet: any;
  multiplier: number;
  setMultiplier: any;
  lastMultipliers: IMultiplier[];
  setLastMultipliers: any;
  balance: number;
  setBalance: any;
  currency: string;
  setCurrency: any;
}

export const TowersContext = React.createContext<ITowersContext>({
  gameID: 0,
  setGameID: () => null,
  flagField: false,
  setFlagField: () => null,
  isLose: false,
  setIsLose: () => null,
  isWin: false,
  setIsWin: () => null,
  mode: "easy",
  setMode: () => null,
  showAllSquares: false,
  setShowAllSquares: () => null,
  revealedTowers: null,
  setRevealedTowers: () => null,
  revealed: null,
  setRevealed: () => null,
  round: 0,
  setRound: () => null,
  actualBet: 0,
  setActualBet: () => null,
  multiplier: 1.0,
  setMultiplier: () => null,
  lastMultipliers: [],
  setLastMultipliers: () => null,
  balance: 0,
  setBalance: () => null,
  currency: "",
  setCurrency: () => null,
});
