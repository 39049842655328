import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Fields} from "./components/TowersField/TowersField";
import {getConfig} from "./config";
import {useSnackbar} from "../../hooks/useSnackbar";
import DimensionsContext from "../../context/dimensions/dimensions";
import {BetWindow} from "../../components/UI/BetWindow/BetWindow";
import Balance from "../../components/UI/Balance/Balance";
import clickaudio from "../../components/shared/sounds/Click.mp3";
import "./Towers.css";
import Multipliers from "../../components/UI/Multipliers/Multipliers";
import {TowersContext} from "../../context/towers/towers";
import {SocketContext} from "../../context/socket/socket";
import {HttpContext} from "../../context/http/http";
import {ReactComponent as TowersBottom} from "./images/towersbottom.svg";
import _ from "lodash.debounce";

export function Towers() {
    const clickQueue = useRef<{ index: number }[]>([]);
    const isProcessingClick = useRef(false);

    const {
        gameID,
        setGameID,
        flagField,
        isLose,
        setIsLose,
        isWin,
        setIsWin,
        mode,
        setMode,
        showAllSquares,
        setShowAllSquares,
        revealedTowers,
        setRevealedTowers,
        revealed,
        setRevealed,
        round,
        setRound,
        multiplier,
        setMultiplier,
        lastMultipliers,
        balance,
        setBalance,
        setCurrency,
        actualBet,
        setActualBet,
    } = useContext(TowersContext);

    const [bet, setBet] = useState<string>(actualBet.toString());

    const {hasBalance, currency, getTowersGameState, createTowers, actionTowers, finishTowers} = useContext(HttpContext);
    const {notify} = useSnackbar();
    const {x} = useContext(DimensionsContext);

    useEffect(() => {
        getTowersGameState().finally(() => {});
    }, []);

    useEffect(() => {
        // Perform any necessary actions with the updated gameID
    }, [gameID, balance, currency]);

    const [towers, setTowers] = useState<number[][]>(
        Array(9).fill(getConfig("medium").starting)
    );

    const handleFinishClick = useCallback(() => {
        finishTowers(gameID).finally(() => {

        });
    }, [finishTowers, gameID, setGameID]);


    const handleClick = useCallback((index: number) => {
        console.log('click');
        clickQueue.current.push({index});
        processClickQueue();
    }, [revealed, round]);

    const processClickQueue = useCallback(() => {
        if (isProcessingClick.current || clickQueue.current.length === 0) {
            return;
        }
        isProcessingClick.current = true;
        const {index} = clickQueue.current.shift()!;
        const sound = new Audio(clickaudio);
        sound.volume = 0.5;
        sound.currentTime = 0;
        sound.play().catch(function (error) {
            console.log('Chrome cannot play sound without user interaction first');
        });
        if (!revealed[round - 1] && round !== 0) {
            notify('Error: Round is not revealed', 'error');
            return;
        }
        setRound((prev: number) => prev + 1);
        console.log(index);
        actionTowers(gameID, index).finally(() => {
            isProcessingClick.current = false;
            processClickQueue();
        });
    }, [gameID, actionTowers, round, revealed]);

    const startGame = useCallback(() => {
        setShowAllSquares(false);
        const sound = new Audio(clickaudio);
        sound.volume = 0.5;
        sound.currentTime = 0;
        sound.play().catch(function (error) {
            console.log("Chrome cannot play sound without user interaction first");
        });
        if (actualBet < 0) {
            notify("Bet value can't be less than 0", "error");
            return;
        }
        if (!hasBalance(actualBet)) {
            notify("You don't have enough balance", "error");
            return;
        }
        createTowers(actualBet, mode).finally(() => {});
    }, [createTowers, actualBet, balance, mode]);

    useEffect(() => {
        setTowers(Array(9).fill(getConfig(mode).starting));
    }, [mode]);

    const resetGame = () => {
        setShowAllSquares(false);
        setIsLose(false);
        setIsWin(false);
        setRevealedTowers(null);
        setRevealed(null);
        setRound(0);
        setMultiplier(1.0);
        setGameID(0);
    };

    return (
        <div className="towers-container">
            <div className="towers-inner">
                {x > 1000 ? (
                    <div className="towers">
                        <div className="towers-left">
                            <BetWindow
                                game="towers"
                                multiplier={multiplier}
                                isEnabledSquare={flagField}
                                bet={bet}
                                actualBet={actualBet}
                                setBet={setBet}
                                setActualBet={setActualBet}
                                startGame={startGame}
                                cashOut={handleFinishClick}
                                options={mode}
                                setOptions={setMode}
                                isLose={isLose}
                                isWin={isWin}
                                resetGame={resetGame}
                                showAllSquares={showAllSquares}
                                balance={balance}
                                currency={currency}
                            />
                        </div>
                        <div className="towers-wrapper">
                            <Multipliers multipliers={lastMultipliers}/>
                            <Fields
                                round={round}
                                handleOnClick={handleClick}
                                fields={towers}
                                revealed={revealed}
                                towers={revealedTowers}
                                flag={flagField}
                                mode={mode}
                                showAllSquares={showAllSquares}
                            />
                            {/*<TowersBottom className="towers-bottom"/>*/}
                        </div>
                    </div>
                ) : (
                    <div className="towers-mob">
                        <div className="towers">
                            <div className="towers-wrapper">
                                <Multipliers multipliers={lastMultipliers}/>
                                <Fields
                                    round={round}
                                    handleOnClick={handleClick}
                                    fields={towers}
                                    revealed={revealed}
                                    towers={revealedTowers}
                                    flag={flagField}
                                    mode={mode}
                                    showAllSquares={showAllSquares}
                                />
                                {/*<TowersBottom className="towers-bottom"/>*/}
                            </div>
                        </div>
                        <BetWindow
                            game="towers"
                            multiplier={multiplier}
                            isEnabledSquare={flagField}
                            bet={bet}
                            actualBet={actualBet}
                            setBet={setBet}
                            setActualBet={setActualBet}
                            startGame={startGame}
                            cashOut={handleFinishClick}
                            options={mode}
                            setOptions={setMode}
                            isLose={isLose}
                            isWin={isWin}
                            resetGame={resetGame}
                            showAllSquares={showAllSquares}
                            balance={balance}
                            currency={currency}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
