import crystalaudio from "../../components/shared/sounds/Crystal.mp3";
import bombaudio from "../../components/shared/sounds/Bomb.mp3";
import winaudio from "../../components/shared/sounds/Win.mp3";

const sounds: { [key: string]: HTMLAudioElement } = {};

export function preloadSounds() {
    sounds.crystal = new Audio(crystalaudio);
    sounds.bomb = new Audio(bombaudio);
    sounds.win = new Audio(winaudio);
}

export function playSound(soundName: string, volume: number) {
    if (sounds[soundName]) {
        sounds[soundName].volume = volume;
        sounds[soundName].currentTime = 0;
        sounds[soundName].play().catch((error) => {
            console.log("Chrome cannot play sound without user interaction first");
        });
    }
}