import styles from "./BetWindow.module.css";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {HttpContext} from "../../../context/http/http";
import {currencyIcons} from "../../shared/svg/svg";
import Decimal from "decimal.js";

type BetPropType = {
    options: any;
    setOptions: (param: any) => void;
    startGame: () => void;
    cashOut: () => void;
    bet: string;
    actualBet: number;
    setBet: Dispatch<SetStateAction<string>>;
    setActualBet: Dispatch<SetStateAction<number>>;
    isEnabledSquare: boolean;
    multiplier: number;
    isLose: boolean;
    isWin: boolean;
    resetGame: () => void;
    game: string;
    showAllSquares: boolean;
    balance: number;
    currency: string;
};

export const BetWindow = ({
                              options,
                              setOptions,
                              startGame,
                              cashOut,
                              bet,
                              actualBet,
                              setBet,
                              setActualBet,
                              isEnabledSquare,
                              multiplier,
                              isLose,
                              isWin,
                              resetGame,
                              game,
                              showAllSquares,
                              balance,
                              currency
                          }: BetPropType) => {


    const { hasBalance, exchangeRate, getBalance, decimalsByCurrency} = useContext(HttpContext);
    const initialBet = 0.0000000;
    const [betInput, setBetInput] = useState(initialBet.toFixed(decimalsByCurrency[currency]));  // For input handling
    const currencyIcon = currencyIcons[currency];

    useEffect(() => {
    }, [actualBet, bet]);

    function truncateToDecimals(num: any, dec: any) {
        const numStr = num.toString();
        const index = numStr.indexOf('.');
        if (index === -1) return numStr; // No decimals to truncate
        const truncated = numStr.slice(0, index + dec + 1); // Slice the string to keep only the specified number of decimals
        return truncated;
    }

    const betOperation = (operation: string) => {
        const decimals = decimalsByCurrency[currency];

        if (operation === "multiple" && !hasBalance(actualBet * 2)) {
            const balance = getBalance();
            setBet(String(balance));
            setActualBet(balance);
            setBetInput(truncateToDecimals(balance, decimals));
            return;
        }

        if (operation === "multiple") {
            const newBet = actualBet * 2;
            setBet(String(newBet));
            setActualBet(newBet);
            setBetInput(truncateToDecimals(newBet, decimals));
        } else if (operation === "division") {
            const newBet = actualBet / 2;
            setBet(String(newBet));
            setActualBet(newBet);
            setBetInput(truncateToDecimals(newBet, decimals));
        } else {
            const balance = getBalance();
            setBet(String(balance));
            setActualBet(balance);
            setBetInput(truncateToDecimals(balance, decimals));
        }
    };

    const validateBet = (value: string) => {
        const regex = /^(0|[1-9]\d*)?(\.\d*)?$/;

        if (value === "" || regex.test(value)) {
            setBetInput(value); // Update the input field directly
        }
    };

    const handleBlur = (value: string) => {
        let finalValue = value.replace(/,/g, '.').replace(/\.$/, "");
        if (!finalValue) {
            setBetInput("0.0000000");
            setBet("0.0000000");
            setActualBet(0);
        } else {
            const finalRegex = /^(0|[1-9]\d*)(\.\d+)?$/;
            if (finalRegex.test(finalValue)) {
                finalValue = parseFloat(finalValue).toFixed(decimalsByCurrency[currency]);
                setBetInput(finalValue);
                setBet(finalValue);
                setActualBet(Number(finalValue));
            } else {
                setBetInput("0.0000000");
                setBet("0.0000000");
                setActualBet(0);
            }
        }
    };

    const formatBalance = (amount: number) => {
        const userLocale = navigator.language;

        const formatter = new Intl.NumberFormat(userLocale, {
            style: "currency",
            currency: "USD",
        });
        return formatter.format(amount);
    };

    const [currIndex, setCurrIndex] = useState(1);

    const calculateProfit = () => {
        // Convert numbers to Decimal and perform the multiplication
        const result = new Decimal(actualBet).mul(new Decimal(multiplier));

        // Get the number of decimals for the current currency
        const decimalPlaces = decimalsByCurrency[currency];

        // Set Decimal global configuration for rounding down
        Decimal.set({ rounding: Decimal.ROUND_DOWN });

        // Format the result with the correct number of decimal places
        return result.toFixed(decimalPlaces);
    }

    // TODO: Place right mode in towers, depending of state

    return isEnabledSquare ? (
        <div className={styles.wrapperGame}>
            <h1 className={styles.multiplier}>x{multiplier.toFixed(2)}</h1>
            <div className={styles.balanceWrapper}>
                <div className={styles.balance}>
                    <div className={styles.balanceLine}>
                        <img src={currencyIcon} alt="currency"/>
                        <h3>{actualBet.toFixed(decimalsByCurrency[currency])}</h3>
                    </div>
                    <h4>Current cashout</h4>
                </div>
                <div className={styles.balance}>
                    <div className={styles.balanceLine}>
                        <img src={currencyIcon} alt="currency"/>
                        <h3>{calculateProfit()}</h3>
                    </div>
                    <h4>Next Tile</h4>
                </div>
            </div>
            <button
                onClick={cashOut}
                className={styles.primaryBtn}
                disabled={multiplier <= 1}
            >
                <p>Cashout</p>
            </button>
        </div>
    ) : isLose ? (
        <div className={styles.wrapperGame}>
            {/* <h1 className={styles.gameOver}>Game Over</h1> */}
            <h1 className={styles.multiplierRed}>x{multiplier.toFixed(2)}</h1>
            <div className={styles.balanceWrapper}>
                <div className={styles.balance}>
                    <div className={styles.balanceLine}>
                        <img src={currencyIcon} alt="currency"/>
                        <h3>{actualBet.toFixed(decimalsByCurrency[currency])}</h3>
                    </div>
                    <h4>Current cashout</h4>
                </div>
                <div className={styles.balance}>
                    <div className={styles.balanceLine}>
                        <img src={currencyIcon} alt="currency"/>
                        <h3>{calculateProfit()}</h3>
                    </div>
                    <h4>Next Tile</h4>
                </div>
            </div>
            <button className={styles.primaryBtn} disabled={!isEnabledSquare}>
                <p>Cashout</p>
            </button>
        </div>
    ) : isWin ? (
        <div className={styles.wrapperGame}>
            {/* <h1 className={styles.gameOver}>Game Over</h1> */}
            <h1 className={styles.multiplier}>x{multiplier.toFixed(2)}</h1>
            <div className={styles.balanceWrapper}>
                <div className={styles.balance}>
                    <div className={styles.balanceLine}>
                        <img src={currencyIcon} alt="currency"/>
                        <h3>{actualBet.toFixed(decimalsByCurrency[currency])}</h3>
                    </div>
                    <h4>Current cashout</h4>
                </div>
                <div className={styles.balance}>
                    <div className={styles.balanceLine}>
                        <img src={currencyIcon} alt="currency"/>
                        <h3>{(actualBet * multiplier).toFixed(decimalsByCurrency[currency])}</h3>
                    </div>
                    <h4>Next Tile</h4>
                </div>
            </div>
            <button className={styles.primaryBtn} disabled={!isEnabledSquare}>
                <p>Cashout</p>
            </button>
        </div>
    ) : (
        <div className={styles.wrapper}>
            <div className={styles.betAmountContainer}>
                <h3>Bet Amount</h3>
                <p className={styles.balanceAmount}>{formatBalance(actualBet * exchangeRate)}</p>
            </div>
            <div className={styles.inputWrapper}>
                <img src={currencyIcon} alt="currency"/>
                <input
                    className={styles.inputAd}
                    value={betInput}
                    onChange={(e) => validateBet(e.target.value)}
                    onBlur={(e) => handleBlur(e.target.value)}
                    type="text"
                />
                <div className={styles.btnInputWrapper}>
                    <button
                        onClick={() => betOperation("division")}
                        className={styles.btnInput}
                    >
                        <p>1/2</p>
                    </button>
                    <button
                        onClick={() => betOperation("multiple")}
                        className={styles.btnInput}
                    >
                        <p>2x</p>
                    </button>
                    <button
                        onClick={() => betOperation("max")}
                        className={styles.btnInput}
                    >
                        <p>Max</p>
                    </button>
                </div>
            </div>
            <h3 style={{marginTop: 20}}>
                {game === "mines" ? "Mines" : "Choose Mode"}
            </h3>
            {game === "mines" ? (
                <div className={styles.inputWrapper}>
                    <div className={styles.customSelect}>
                        <select
                            value={options}
                            onChange={(e) => setOptions(Number(e.target.value))}
                            className={styles.selectInput}
                        >
                            {Array.from({length: 24}, (_, i) => i + 1).map((numMines) => (
                                <option key={numMines} value={numMines}>
                                    {numMines}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : (
                <div className={styles.btnWrapper}>
                    {["easy", "medium", "hard", "nightmare"].map((item, index) => {
                        return (
                            <button
                                key={index}
                                className={
                                    currIndex === index ? styles.rowBtnActive : styles.rowBtn
                                }
                                onClick={() => {
                                    setCurrIndex(index);
                                    setOptions(item);
                                    resetGame();
                                }}
                            >
                                {item}
                            </button>
                        );
                    })}
                </div>
            )}
            <button
                style={{marginTop: 30, width: "100%"}}
                onClick={startGame}
                className={styles.primaryBtn}
            >
                <p>Place Bet</p>
            </button>
        </div>
    );
};
