import { jwtDecode } from "jwt-decode";

import {useContext, useEffect, useState} from "react";

// import { useSnackbar } from "../../hooks/useSnackbar";
import {Mode} from "fs";
import {TowersContext} from "../towers/towers";
import {MinesContext} from "../mines/mines";
import {PlinkoContext} from "../plinko/plinko";
import {HttpContext} from "./http";
import {playSound, preloadSounds} from "./utils";
import queryString from "query-string";
import {DiceContext} from "../dice/dice";
import {use} from "matter-js";


export function HttpProvider({ children }: any) {

    const { auth: authToken, test } = queryString.parse(window.location.search);

    let serverURL = process.env.REACT_APP_GAME_SERVER_URL;

    if (test) {
        serverURL = process.env.REACT_APP_GAME_SERVER_URL_TEST;
    }

    let decodedToken: any;
    try {
        decodedToken = jwtDecode(String(authToken));
    } catch (error) {
        console.error("Error:", error);
        decodedToken = {};
    }

    const [currency, setCurrency] = useState(decodedToken.data.currency || "BTC");
    const [exchangeRate, setExchangeRate] = useState(0);
    const [cryptoBalance, setCryptoBalance] = useState("0");

    const decimalsByCurrency : { [key: string]: number }  = {
        "BTC": 8,
        "ETH": 8,
        "USDT": 6,
        "BNB": 8,
        "CYT": 4,
        "DOGE": 4,
        "MATIC": 6,
        "LTC": 6,
        "XMR": 6,
        "CAKE": 4,
        "SHIB": 1,
    }

    const towersAPI = useContext(TowersContext);
    const minesAPI = useContext(MinesContext);
    const plinkoAPI = useContext(PlinkoContext);
    const diceAPI = useContext(DiceContext);

    function sendUpdateToParent(symbol: string, amount: string, action?: string) {
        if (action === undefined) {
            action = "";
        }
        window.parent.postMessage({
            type: 'UPDATE_BALANCE',
            data: { symbol, amount, action }
        }, '*'); // The '*' is the targetOrigin parameter
    }

    function updateExchangeRate() {
        window.parent.postMessage({
            type: 'GET_EXCHANGE_RATE',
            data: { currency }
        }, '*'); // The '*' is the targetOrigin parameter
    }

    function updateCryptoBalance() {
        window.parent.postMessage({
            type: 'GET_CRYPTO_BALANCE',
            data: { currency }
        }, '*'); // The '*' is the targetOrigin parameter
    }

    function getDataFromParent(event: any)  {
        const { type, data } = event.data;
        if (type === 'EXCHANGE_RATE') {
            setExchangeRate(data);
        } else if (type === 'CRYPTO_BALANCE') {
            setCryptoBalance(data);
        }
    }

    useEffect(() => {

    }, [cryptoBalance]);

    useEffect(() => {

    }, [exchangeRate]);

    useEffect(() => {
        preloadSounds();
        updateExchangeRate();
        updateCryptoBalance();

        window.addEventListener('message', getDataFromParent);

        const interval = setInterval(() => {
            updateExchangeRate();
            updateCryptoBalance();
        }, 200);

        return () => {
            clearInterval(interval);
            // Remove event listener
            window.removeEventListener('message', getDataFromParent);
        };
    }, [currency]);

    const parseBalance = (balanceString: any) => {
        // Remove any non-numeric characters except comma and period
        let cleanedString = balanceString.replace(/[^\d,\.]/g, '');

        // Detect the use of comma or period as a thousand separator based on the last occurrence
        const lastComma = cleanedString.lastIndexOf(',');
        const lastPeriod = cleanedString.lastIndexOf('.');

        // Determine if comma or period is used as a decimal separator
        if (lastComma > lastPeriod) {
            // Comma is the decimal separator
            cleanedString = cleanedString.replace(/\./g, ''); // Remove periods
            cleanedString = cleanedString.replace(',', '.'); // Replace comma with period
        } else {
            // Period is the decimal separator
            cleanedString = cleanedString.replace(/,/g, ''); // Remove commas
        }

        return parseFloat(cleanedString);
    }

    const hasBalance = (betValue: number) => {
        const numericBalance = parseBalance(cryptoBalance);
        // console.log(numericBalance);
        // console.log(betValue);
        return numericBalance >= betValue;
    };

    const getBalance = () => {
        return parseBalance(cryptoBalance);
    }

    const getPlinkoGameState = async (setBalance: any): Promise<void> => {
        try {
            const url = serverURL + "/plinko/state";
            // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            setBalance(data.balance);
            setCurrency(data.cryptocurrency);
            updateExchangeRate();
            updateCryptoBalance();
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const plinkoGame = async (lines: any, betValue: number, mode: any, mobile: boolean): Promise<void> => {
        try {
            const url = serverURL + "/plinko/start";
            // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    bet_value: betValue.toFixed(18).toString(),
                    currency: "USD",
                    lines: lines,
                    mode: mode,
                    mobile: mobile,
                }),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            sendUpdateToParent(data.cryptocurrency, data.bet_value_crypto, "subtract");
            plinkoAPI.setGameData({...data});
            setTimeout(() => {
                updateExchangeRate();
                updateCryptoBalance();
            }, 50);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const getDiceGameState = async (setBalance: any): Promise<void> => {
        try {
            const url = serverURL + "/dice/state";
            // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            sendUpdateToParent(data.cryptocurrency, data.balance_crypto);
            setCurrency(data.cryptocurrency);
            updateExchangeRate();
            updateCryptoBalance();

            setBalance(data.balance);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const diceGame = async (betValue: number, mode: Mode, rollValue: number, playDice: any): Promise<void> => {
        try {
            const url = serverURL + "/dice/start";
            // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    bet_value: betValue.toFixed(18).toString(),
                    currency: "USD",
                    mode: mode,
                    roll_value: rollValue.toFixed(2).toString(),
                }),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            setTimeout(() => {
                diceAPI.setTicket(data.ticket);
                diceAPI.setStatus(data.status);
                diceAPI.setGameData({...data});
                playDice(data.ticket, data.status, data.balance, data);
                sendUpdateToParent(data.cryptocurrency, data.old_balance_crypto);
                setTimeout(() => {
                    updateExchangeRate();
                    updateCryptoBalance();
                }, 50);
            }, 50);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getTowersGameState = async (): Promise<void> => {
        try {
            const url = serverURL + "/towers/state";
            // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            setCurrency(data.cryptocurrency);
            updateExchangeRate();
            updateCryptoBalance();

            if (data.message === "No active game found") {
                // Handle the case when no active game is found
                towersAPI.setIsLose(false);
                towersAPI.setIsWin(false);
                towersAPI.setRevealedTowers(null);
                towersAPI.setRevealed(null);
                towersAPI.setRound(0);
                towersAPI.setMultiplier(1.0);
                towersAPI.setShowAllSquares(false);
                towersAPI.setRevealedTowers(null);
                towersAPI.setGameID(0);
                towersAPI.setBalance(data.balance);
            } else {
                towersAPI.setFlagField(true);
                towersAPI.setRevealed(data.revealed);
                towersAPI.setRound(data.round);
                towersAPI.setMultiplier(data.multiplier);
                towersAPI.setGameID(data.game_id);
                towersAPI.setMode(data.mode);
                towersAPI.setActualBet(data.bet_value);

                if (data.round >= 9) {
                    await finishTowers(data.game_id);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const createTowers = async (betValue: number, mode: Mode): Promise<void> => {
        try {
            const url = serverURL + "/towers/start";
             // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    bet_value: betValue.toFixed(18).toString(),
                    currency: "USD",
                    mode: mode,
                }),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            towersAPI.setFlagField(true);
            towersAPI.setRevealed(data.revealed);
            towersAPI.setRound(data.round);
            towersAPI.setMultiplier(data.multiplier);
            towersAPI.setGameID(data.game_id);
            sendUpdateToParent(data.cryptocurrency, data.balance_crypto);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const actionTowers = async (gameID: number, index: number): Promise<void> => {
        try {
            const url = serverURL + "/towers/reveal";
             // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    index: index,
                    game_id: gameID.toString(),
                }),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            if (data.status === "lost") {
                towersAPI.setIsLose(true);
                towersAPI.setFlagField(false);
                towersAPI.setLastMultipliers((prev: any) => prev.slice(0, 4));
                towersAPI.setBalance(data.balance);

                towersAPI.setLastMultipliers((prev: any) => [
                    { id: new Date().getTime(), value: data.multiplier, win: false },
                    ...prev,
                ]);

                playSound("bomb", 0.2);
                setTimeout(() => {
                    towersAPI.setIsLose(false);
                    towersAPI.setIsWin(false);
                    towersAPI.setRevealedTowers(null);
                    towersAPI.setRevealed(null);
                    towersAPI.setRound(0);
                    towersAPI.setMultiplier(1.0);
                    towersAPI.setGameID(0);
                    towersAPI.setShowAllSquares(true);
                    towersAPI.setRevealedTowers(data.towers);
                }, 1500);
            } else {
                playSound("crystal", 0.5);
            }
            towersAPI.setRevealed(data.revealed);
            towersAPI.setRound(data.round);
            towersAPI.setMultiplier(data.multiplier);
            if (data.round >= 9) {
                await finishTowers(gameID);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const finishTowers = async (gameID: number): Promise<void> => {
        try {
            const url = serverURL + "/towers/cashout";
             // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    game_id: gameID.toString(),
                }),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            towersAPI.setFlagField(false);
            towersAPI.setIsWin(true);
            towersAPI.setLastMultipliers((prev: any) => prev.slice(0, 4));
            towersAPI.setBalance(data.balance);

            towersAPI.setLastMultipliers((prev: any) => [
                { id: new Date().getTime(), value: data.multiplier, win: true },
                ...prev,
            ]);

            sendUpdateToParent(data.cryptocurrency, data.balance_crypto);

            playSound("win", 0.5);
            setTimeout(() => {
                towersAPI.setIsLose(false);
                towersAPI.setIsWin(false);
                towersAPI.setRevealedTowers(null);
                towersAPI.setRevealed(null);
                towersAPI.setRound(0);
                towersAPI.setMultiplier(1.0);
                towersAPI.setShowAllSquares(true);
                towersAPI.setRevealedTowers(data.towers);
                towersAPI.setGameID(0);
                updateExchangeRate();
                updateCryptoBalance();
            }, 150);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        // Call actionMines or perform other necessary actions
        // actionMines('reveal', 1, 0, 0);
    }, [minesAPI.gameID, minesAPI.isLose]);


    const getMinesGameState = async (): Promise<void> => {
        try {
            const url = serverURL + "/mines/state";
            // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            setCurrency(data.cryptocurrency);
            updateExchangeRate();
            updateCryptoBalance();

            if (data.message === "No active game found") {
                // Handle the case when no active game is found
                minesAPI.setFlagField(false);
                minesAPI.setIsLose(false);
                minesAPI.setMultiplier(0);
                minesAPI.setGameID(null);
                minesAPI.setBalance(data.balance);
                minesAPI.setMines(
                    Array(25).fill(null).map((_, index) => ({
                        id: index + 1,
                        isChecked: false,
                        isMine: false,
                    }))
                );
            } else {
                // Handle the case when an active game is found
                minesAPI.setFlagField(true);
                minesAPI.setIsLose(data.status === "lost");
                minesAPI.setMultiplier(data.multiplier);
                minesAPI.setGameID(data.game_id);
                minesAPI.setBalance(data.balance);
                minesAPI.setActualBet(data.bet_value);
                // After 250ms set the mines
                setTimeout(() => {
                    const mineArr: number[] = [].concat(...data.revealed);
                    minesAPI.setMines(
                        mineArr.map((item: any, index: number) => ({
                            id: index + 1,
                            isChecked: item !== 0,
                            isMine: item === -1,
                        }))
                    );
                }, 250);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const createMines = async (betValue: number, numMines: number): Promise<void> => {
        try {
            const url = serverURL + "/mines/start";
             // Use the authToken extracted from the query string
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    bet_value: betValue.toFixed(18).toString(),
                    currency: "USD",
                    num_mines: numMines,
                }),
            };

            const response = await fetch(url, requestOptions);
            const data = await response.json();
            minesAPI.setFlagField(true);
            minesAPI.setIsLose(false);
            minesAPI.setMultiplier(data.multiplier);
            minesAPI.setGameID(data.game_id);
            minesAPI.setMultiplier(data.multiplier);
            minesAPI.setBalance(data.balance);
            sendUpdateToParent(data.cryptocurrency, data.balance_crypto);

            // After 250ms set the mines
            setTimeout(() => {
                const mineArr: number[] = [].concat(...data.revealed);
                minesAPI.setMines(
                    mineArr.map((item: any, index: number) => ({
                        id: index + 1,
                        isChecked: item !== 0,
                        isMine: item === -1,
                    }))
                );
            }, 250);


        } catch (error) {
            console.error("Error:", error);
        }
    };

    const actionMines = async (gameID: number, action: string, cell: number, indexX: number, indexY: number): Promise<void> => {
        try {

            let url = ''


             // Use the authToken extracted from the query string
            let requestOptions : any = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`,
                }
            }

            if (action === "reveal") {
                url = serverURL + "/mines/reveal";
                requestOptions.body = JSON.stringify({
                    game_id: gameID.toString(),
                    index_x: indexX,
                    index_y: indexY,
                    seq_num: cell,
                });
            } else if (action === "cashout") {
                url = serverURL + "/mines/cashout";
                requestOptions.body = JSON.stringify({
                    game_id: gameID.toString(),
                });
            }

            const response = await fetch(url, requestOptions);
            const data = await response.json();

            if (data.status === "won") {
                await finishMines(data);
                return;
            }

            if (data.error) {
                return;
            }

            if (data.status === "lost") {
                minesAPI.setIsLose(true);
                playSound("bomb", 0.2);

                minesAPI.setFlagField(false);
                minesAPI.setLastMultipliers((prev: any) => prev.slice(0, 4));

                minesAPI.setLastMultipliers((prev: any) => [
                    { id: new Date().getTime(), value: data.multiplier, win: false },
                    ...prev,
                ]);
                setTimeout(() => {
                    minesAPI.setMultiplier(1.0);
                    minesAPI.setIsLose(false);
                    minesAPI.setIsWin(false);
                    minesAPI.setShowAllSquares(true);
                    const mineArr: number[] = [].concat(...data.mines);
                    minesAPI.setMines(
                        mineArr.map((item: any, index: number) => ({
                            id: index + 1,
                            isChecked: item !== 0,
                            isMine: item === -1,
                        }))
                    );
                    updateExchangeRate();
                    updateCryptoBalance();
                }, 1500);
            } else {
                if (!minesAPI.isLose) {
                    playSound("crystal", 0.5);
                }
            }

            const mineArr: number[] = [].concat(...data.revealed);
            minesAPI.setMultiplier(data.multiplier);
            minesAPI.setMines(
                mineArr.map((item: any, index: number) => ({
                    id: index + 1,
                    isChecked: item !== 0,
                    isMine: item === -1,
                }))
            );
            if (data.round >= 25 - data.numMines) {
                await finishMines(data);
            }
        } catch (error) {
            // Handle any errors
            console.error("Error:", error);
        }
    };

    const finishMines = async (data: any): Promise<void> => {
        playSound("win", 0.5);

        minesAPI.setFlagField(false);
        minesAPI.setIsWin(true);
        minesAPI.setLastMultipliers((prev: any) => prev.slice(0, 4));

        minesAPI.setLastMultipliers((prev: any) => [
            { id: new Date().getTime(), value: data.multiplier, win: true },
            ...prev,
        ]);

        sendUpdateToParent(data.cryptocurrency, data.balance_crypto);
        setTimeout(() => {
            minesAPI.setMultiplier(1.0);
            minesAPI.setGameID(0);
            minesAPI.setIsLose(false);
            minesAPI.setIsWin(false);
            minesAPI.setShowAllSquares(true);
            minesAPI.setBalance(data.balance);
            const mineArr: number[] = [].concat(...data.mines);
            minesAPI.setMines(
                mineArr.map((item: any, index: number) => ({
                    id: index + 1,
                    isChecked: item !== 0,
                    isMine: item === -1,
                }))
            );
            updateExchangeRate();
            updateCryptoBalance();
        }, 150);
    };

    return (
        <HttpContext.Provider
            value={{
                currency,
                getBalance,
                cryptoBalance,
                exchangeRate,
                hasBalance,
                decimalsByCurrency,
                setCurrency,
                sendUpdateToParent,
                getTowersGameState,
                createTowers,
                actionTowers,
                finishTowers,
                getMinesGameState,
                createMines,
                actionMines,
                diceGame,
                getDiceGameState,
                getPlinkoGameState,
                plinkoGame,
            }}
        >
            {children}
        </HttpContext.Provider>
    );
}
