import {useState} from "react";
import {MinesContext} from "./mines";
import {IMultiplier} from "../../Games/Towers/types";

interface IMines {
    id: number;
    isMine: boolean;
    isChecked: boolean;
}

export default function MinesProvider({children}: any) {
    const [gameID, setGameID] = useState(0);
    const [flagField, setFlagField] = useState<boolean>(false);
    const [isLose, setIsLose] = useState<boolean>(false);
    const [isWin, setIsWin] = useState<boolean>(false);
    const [showAllSquares, setShowAllSquares] = useState<boolean>(false);

    const [numMines, setNumMines] = useState(3);
    const [mines, setMines] = useState<IMines[]>([]);
    const [actualBet, setActualBet] = useState(0);
    const [multiplier, setMultiplier] = useState(1.0);
    const [lastMultipliers, setLastMultipliers] = useState<IMultiplier[]>([]);

    const [balance, setBalance] = useState(0);
    const [currency, setCurrency] = useState("USD");

    return (
        <MinesContext.Provider
            value={{
                gameID,
                setGameID,
                flagField,
                setFlagField,
                isLose,
                setIsLose,
                isWin,
                setIsWin,
                showAllSquares,
                setShowAllSquares,
                numMines,
                setNumMines,
                mines,
                setMines,
                actualBet,
                setActualBet,
                multiplier,
                setMultiplier,
                lastMultipliers,
                setLastMultipliers,
                balance,
                setBalance,
                currency,
                setCurrency,
            }}
        >
            {children}
        </MinesContext.Provider>
    );
}
