import { useState } from "react";
import { PlinkoContext } from "./plinko";

export default function PlinkoProvider({ children }: any) {
  const [gameData, setGameData] = useState(null);
  const [balance, setBalance] = useState(0);

  return (
    <PlinkoContext.Provider
      value={{
        gameData,
        setGameData,
        balance,
        setBalance,
      }}
    >
      {children}
    </PlinkoContext.Provider>
  );
}
