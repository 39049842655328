import styles from "./DiceScreen.module.css";
import { currencyIcons } from "../../../components/shared/svg/svg";

import cross from "../images/x.svg";
import percent from "../images/percent.svg";
import flip from "../images/flip.svg";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import hr from "../images/hr.svg";
import DimensionsContext from "../../../context/dimensions/dimensions";
import {useSnackbar} from "../../../hooks/useSnackbar";
import DiceAnimations from "./DiceAnimations";
import scrollaudio from "../sounds/Scroll.mp3";
import diceloadingaudio from "../sounds/Diceloading.mp3";
import clickaudio from "../../../components/shared/sounds/Click.mp3";
import winaudio from "../../../components/shared/sounds/Win.mp3";
import {DiceContext} from "../../../context/dice/dice";
import {HttpContext} from "../../../context/http/http";

export const DiceScreen = () => {
    const parseBalance = (balanceString: any) => {
        // Remove any non-numeric characters except comma and period
        let cleanedString = balanceString.replace(/[^\d,\.]/g, '');

        // Detect the use of comma or period as a thousand separator based on the last occurrence
        const lastComma = cleanedString.lastIndexOf(',');
        const lastPeriod = cleanedString.lastIndexOf('.');

        // Determine if comma or period is used as a decimal separator
        if (lastComma > lastPeriod) {
            // Comma is the decimal separator
            cleanedString = cleanedString.replace(/\./g, ''); // Remove periods
            cleanedString = cleanedString.replace(',', '.'); // Replace comma with period
        } else {
            // Period is the decimal separator
            cleanedString = cleanedString.replace(/,/g, ''); // Remove commas
        }

        return parseFloat(cleanedString);
    }

    const betInputWrapperRef = useRef<HTMLInputElement>(null);
    const {x} = useContext(DimensionsContext);
    const {notify} = useSnackbar();
    const { cryptoBalance, getBalance, exchangeRate, currency, decimalsByCurrency,
        sendUpdateToParent, diceGame, getDiceGameState} = useContext(HttpContext);

    const [balanceVersion, setBalanceVersion] = useState(0);

    const currentBalance = useMemo(() => {
        return getBalance();
    }, [balanceVersion]);

    const initialBet = 0.0000000;

    const currencyIcon = currencyIcons[currency];

    const [wonTicket, setWonTicket] = useState<boolean>(false);
    const [processAnimStarted, setProcessAnimStarted] = useState<boolean>(false);
    const [startDiceGame, setStartDiceGame] = useState<boolean>(false);
    const [ticketData, setTicketData] = useState<number>(0);
    const [value, setValue] = useState<number>(50);
    const [bet, setBet] = useState(0.0000000);  // For internal calculations and storage
    const [betInput, setBetInput] = useState(initialBet.toFixed(decimalsByCurrency[currency]));  // For input handling

    const [flipStatus, setFlipStatus] = useState("under");
    const {
        gameData, setGameData,
        ticket, setTicket,
        status, setStatus,
    } = useContext(DiceContext);

    const [balance, setBalance] = useState<number>(0);

    const [soundDelay, setSoundDelay] = useState(false);

    const clickSound = useRef(new Audio(clickaudio));
    const diceLoadingSound = useRef(new Audio(diceloadingaudio));
    const winSound = useRef(new Audio(winaudio));
    const scrollSound = useRef(new Audio(scrollaudio));

    useEffect(() => {
        getDiceGameState(setBalance).finally(() => {
        });
        clickSound.current.volume = 0.5;
        diceLoadingSound.current.playbackRate = 2;
        diceLoadingSound.current.volume = 0.5;
        winSound.current.volume = 0.2;
    }, []);

    useEffect(() => {

        // eslint-disable-next-line
    }, [gameData, balance]);

    useEffect(() => {
        if (ticket && status) {
            // playDice();
            setTicket(null);
            setStatus(null);
        }
        // eslint-disable-next-line
    }, [ticket, status]);

    useEffect(() => {
        setBalanceVersion(prev => prev + 1);
    }, [cryptoBalance]);

    const multiplierCalculating =
        flipStatus === "under" ? 99 / value : 99 / (100 - value);

    const [multiplier, setMultiplier] = useState<string>((Math.round(multiplierCalculating * 10000) / 10000).toFixed(4));

    const calculateValueFromMultiplier = (multiplier: number) => {
        const value = flipStatus === "under" ? (99 / multiplier) : (100 - (99 / multiplier));
        return Math.round(value * 100) / 100;
    };

    const calculateMultiplierFromValue = (value: number, flipStatus: string) => {
        let multiplier: number;
        if (flipStatus === "under") {
            multiplier = 99 / value;
        } else { // "over"
            multiplier = 99 / (100 - value);
        }
        // Round to 4 decimal places
        return Math.round(multiplier * 10000) / 10000;
    };

    function truncateToDecimals(num: any, dec: any) {
        const numStr = num.toString();
        const index = numStr.indexOf('.');
        if (index === -1) return numStr; // No decimals to truncate
        const truncated = numStr.slice(0, index + dec + 1); // Slice the string to keep only the specified number of decimals
        return truncated;
    }

    const betOperation = (operation: string) => {
        const decimals = decimalsByCurrency[currency];

        if (operation === "multiple" && !hasBalance(bet * 2)) {
            const balance = getBalance();
            setBet(balance);
            setBetInput(truncateToDecimals(balance, decimals));
            return;
        }

        if (operation === "multiple") {
            const newBet = bet * 2;
            setBet(newBet);
            setBetInput(truncateToDecimals(newBet, decimals));
        } else if (operation === "division") {
            const newBet = bet / 2;
            setBet(newBet);
            setBetInput(truncateToDecimals(newBet, decimals));
        } else {
            const balance = getBalance();
            setBet(balance);
            setBetInput(truncateToDecimals(balance, decimals));
        }
    };

    const valueCalculating =
        flipStatus === "under" ? value.toFixed(2) : (100 - value).toFixed(2);

    const startGame = useCallback(async () => {
        try {
            setStartDiceGame(true);
            clickSound.current.play().finally(() => {});

            if (bet < 0) {
                setStartDiceGame(false);
                notify("Bet value can't be less than 0", "error");
                return;
            }
            if (!hasBalance(bet)) {
                setStartDiceGame(false);
                // If you want to notify about insufficient balance, uncomment the next line
                notify("You don't have enough balance", "error");
                return;
            }
            diceLoadingSound.current.play().finally(() => {});
            diceGame(bet, flipStatus === "under" ? 0 : 1, value, playDice).finally(() => {
            });
        } catch (e) {
            console.error(e);
            setStartDiceGame(false);
        }
    }, [value, bet, balance, flipStatus, ticket, status, gameData, currentBalance]); // Include all used values as dependencies

    const hasBalance = (betValue: number) => {
        return currentBalance >= betValue;
    };

    const playDice = (ticket: any, status: any, balance: any, data: any) => {
        setProcessAnimStarted(true);
        setTicketData(0);
        setTicketData(ticket);
        if (status === "won") {
            setTimeout(() => {
                winSound.current.play().finally(() => {});

                sendUpdateToParent(data.cryptocurrency, data.balance_crypto);
            }, 550);
            setWonTicket(true);
        } else {
            setWonTicket(false);
        }
        setTimeout(() => {
            setStartDiceGame(false);
            setBalance(balance);
        }, 500);
    };

    const changeFlipStatus = () => {
        flipStatus === "under" ? setFlipStatus("over") : setFlipStatus("under");
        setValue(prevValue => {
            const newValue = 100 - prevValue;
            if (newValue > 98) {
                setMultiplier(calculateMultiplierFromValue(98, flipStatus).toFixed(2));
                return 98;
            }
            if (newValue < 0.01) {
                setMultiplier(calculateMultiplierFromValue(0.01, flipStatus).toFixed(2));
                return 0.01;
            }
            return newValue;
        });
    };

    const validateBet = (value: string) => {
        const regex = /^(0|[1-9]\d*)?(\.\d*)?$/;

        if (value === "" || regex.test(value)) {
            setBetInput(value); // Update the input field directly
        }
    };

    const handleBlur = (value: string) => {
        let finalValue = value.replace(/,/g, '.').replace(/\.$/, "");
        if (!finalValue) {
            setBetInput("0.0000000");
            setBet(0);
        } else {
            const finalRegex = /^(0|[1-9]\d*)(\.\d+)?$/;
            if (finalRegex.test(finalValue)) {
                finalValue = parseFloat(finalValue).toFixed(decimalsByCurrency[currency]);
                setBetInput(finalValue);
                setBet(parseFloat(finalValue));
            } else {
                setBetInput("0.0000000");
                setBet(0);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let sliderValue = Number(event.target.value);
        if (sliderValue < 2 || sliderValue > 98) {
            return;
        }
        if (!soundDelay) {
            scrollSound.current.play().finally(() => {});

            setSoundDelay(true);
            setTimeout(() => {
                setSoundDelay(false);
            }, 50);
        }
        setValue(sliderValue);

        // Calculate and update the multiplier based on the new value
        const newMultiplier = flipStatus === "under" ? 99 / sliderValue : 99 / (100 - sliderValue);
        setMultiplier((Math.round(newMultiplier * 10000) / 10000).toFixed(4));
    };

    const formatBalance = (amount: number) => {
        const userLocale = navigator.language;
        const formatter = new Intl.NumberFormat(userLocale, {
            style: "currency",
            currency: gameData.currency || "USD",
        });
        return formatter.format(amount);
    };

    return (
        <div className={styles.dicecontainer}>
            <div className={styles.wrapper}>
                <div className={styles.hrContainer}>
                    <div className={styles.hrWrapper}>
                        <h1>1</h1>
                        <img src={hr} className={styles.hrImg} alt=""/>
                    </div>
                    <div className={styles.hrWrapper}>
                        <h1>25</h1>
                        <img src={hr} className={styles.hrImg} alt=""/>
                    </div>
                    <div className={styles.hrWrapper}>
                        <h1>50</h1>
                        <img src={hr} className={styles.hrImg} alt=""/>
                    </div>
                    <div className={styles.hrWrapper}>
                        <h1>75</h1>
                        <img src={hr} className={styles.hrImg} alt=""/>
                    </div>
                    <div className={styles.hrWrapper}>
                        <h1>100</h1>
                        <img src={hr} className={styles.hrImg} alt=""/>
                    </div>
                </div>
                <div ref={betInputWrapperRef} className="w-full relative">
                    <div className={styles.animationWrapper}>
                        <DiceAnimations
                            ticketData={ticketData}
                            wonTicket={wonTicket}
                            wrapperWidth={betInputWrapperRef.current?.offsetWidth}
                            processAnimStarted={processAnimStarted}
                            setProcessAnimStarted={setProcessAnimStarted}
                        />
                    </div>
                    <input
                        type="range"
                        min={0}
                        max={100}
                        step="1"
                        value={value}
                        disabled={startDiceGame}
                        onChange={handleChange}
                        className={styles.slider}
                        style={{
                            background: `linear-gradient(to right, ${
                                flipStatus === "under" ? "#51D231" : "#C53111"
                            } 0%, ${
                                flipStatus === "under" ? "#51D231" : "#C53111"
                            } ${value}%, ${
                                flipStatus === "under" ? "#C53111" : "#51D231"
                            } ${value}%, ${
                                flipStatus === "under" ? "#C53111" : "#51D231"
                            } 100%)`,
                            marginTop: 42,
                            height: 6,
                            padding: 0,
                        }}
                    />
                </div>
                {x >= 600 ? (
                    <>
                        <button
                            onClick={startGame}
                            disabled={startDiceGame}
                            className={styles.primaryBtn}
                        >
                            <p>Place Bet</p>
                        </button>
                        <div className={styles.betWindow}>
                            <div className={styles.betWrapper}>
                                <div style={{width: "100%"}}>
                                    <div className={styles.betAmountContainer}>
                                        <h3>Bet Amount</h3>
                                        <p className={styles.balanceAmount}>{formatBalance(bet * exchangeRate)}</p>
                                    </div>
                                    <div
                                        className={styles.betInputWrapper}
                                        style={{width: "100%"}}
                                    >
                                        <img
                                            src={currencyIcon}
                                            style={{width: 25, height: 25}}
                                            alt="currency"
                                        />
                                        <input
                                            onChange={(e) => validateBet(e.target.value)}
                                            onBlur={(e) => handleBlur(e.target.value)}
                                            value={betInput}
                                            type="text"
                                            className={styles.inputAd}
                                            // style={{ width: "100%" }}
                                        />
                                        <div className={styles.btnInputWrapper}>
                                            <button
                                                onClick={() => betOperation("division")}
                                                className={styles.btnInput}
                                            >
                                                <p>1/2</p>
                                            </button>
                                            <button
                                                onClick={() => betOperation("multiple")}
                                                className={styles.btnInput}
                                            >
                                                <p>2x</p>
                                            </button>
                                            <button
                                                onClick={() => betOperation("max")}
                                                className={styles.btnInput}
                                            >
                                                <p>Max</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "100%"}}>
                                    <h3>Profit</h3>
                                    <div
                                        className={styles.betInputWrapper}
                                        style={{width: "100%"}}
                                    >
                                        <img
                                            src={currencyIcon}
                                            style={{width: 25, height: 25}}
                                            alt="currency"
                                        />
                                        <input
                                            value={(Number(bet) * Number(multiplier)).toFixed(decimalsByCurrency[currency])}
                                            readOnly
                                            type="text"
                                            className={styles.inputAd}
                                            style={{width: "100%"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: 10}} className={styles.betWrapper}>
                                <div style={{width: "100%"}}>
                                    <h3>Payout</h3>
                                    <div
                                        className={styles.betInputWrapper}
                                        style={{width: "100%"}}
                                    >
                                        <img src={cross} style={{width: 18, height: 15}} alt=""/>
                                        <input
                                            value={multiplier}
                                            type="text"
                                            className={styles.inputAd}
                                            style={{width: "100%"}}
                                            onChange={(e) => {
                                                const newMultiplier = Number(e.target.value);
                                                setMultiplier(e.target.value)
                                                if (newMultiplier >= 1.0102 && newMultiplier <= 9900) {
                                                    const newValue = calculateValueFromMultiplier(newMultiplier);
                                                    setValue(newValue);
                                                }

                                                if (newMultiplier < 1.0102) {
                                                    notify("Value must be greater than or equal to 1.0102", "info");
                                                } else if (newMultiplier > 9900) {
                                                    notify("Value must be less than or equal to 9900", "info");
                                                }
                                            }}
                                            onBlur={() => {
                                                const newMultiplier = Number(multiplier);
                                                if (newMultiplier >= 1.0102 && newMultiplier <= 9900) {
                                                    setMultiplier((Math.round(newMultiplier * 10000) / 10000).toFixed(4));
                                                } else {
                                                    let finalMultiplier = 0;
                                                    if (newMultiplier < 1.0102) {
                                                        setMultiplier("1.0102");
                                                        finalMultiplier = 1.0102;
                                                    } else if (newMultiplier > 9900) {
                                                        setMultiplier("9900.0000");
                                                        finalMultiplier = 9900;
                                                    } else {
                                                        finalMultiplier = (Math.round(multiplierCalculating * 10000) / 10000);
                                                        setMultiplier(finalMultiplier.toFixed(4));
                                                    }

                                                    const newValue = calculateValueFromMultiplier(finalMultiplier);
                                                    setValue(newValue);
                                                }
                                            }}
                                            onClick={(e) => {
                                                const input = e.target as HTMLInputElement;
                                                input.select();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{width: "100%"}}>
                                    <h3>Roll {flipStatus}</h3>
                                    <div
                                        className={styles.betInputWrapper}
                                        style={{width: "100%"}}
                                    >
                                        <input
                                            readOnly
                                            value={value}
                                            type="text"
                                            className={styles.inputAd}
                                            style={{width: "100%"}}
                                        />
                                        <div
                                            className={styles.btnInputWrapper}
                                            style={{right: 16}}
                                        >
                                            <button
                                                onClick={changeFlipStatus}
                                                className={styles.btnInput}
                                            >
                                                <img src={flip} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width: "100%"}}>
                                    <h3>Win Chance</h3>
                                    <div
                                        className={styles.betInputWrapper}
                                        style={{width: "100%"}}
                                    >
                                        <img
                                            src={percent}
                                            style={{width: 21, height: 17}}
                                            alt=""
                                        />
                                        <input
                                            type="text"
                                            className={styles.inputAd}
                                            readOnly
                                            value={valueCalculating}
                                            style={{width: "100%"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*<div*/}
                            {/*    style={{*/}
                            {/*      display: "flex",*/}
                            {/*      alignItems: "center",*/}
                            {/*      justifyContent: "center",*/}
                            {/*      marginTop: "10px",*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*  <div*/}
                            {/*      style={{border: "solid 2px #212432", borderRadius: "12px"}}*/}
                            {/*  >*/}
                            {/*    <Balance/>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                    </>
                ) : (
                    <div className={styles.betWindow}>
                        {/*<div className={styles.betWrapper}>*/}
                        {/*  <div*/}
                        {/*      style={{border: "solid 2px #212432", borderRadius: "12px"}}*/}
                        {/*  >*/}
                        {/*    <Balance/>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className={styles.betWrapper}>
                            <div style={{width: "100%"}}>
                                <div className={styles.betAmountContainer}>
                                    <h3>Bet Amount</h3>
                                    <p className={styles.balanceAmount}>{formatBalance(bet * exchangeRate)}</p>
                                </div>
                                <div
                                    className={styles.betInputWrapper}
                                    style={{width: "100%"}}
                                >
                                    <img
                                        src={currencyIcon}
                                        style={{width: 25, height: 25}}
                                        alt="currency"
                                    />
                                    <input
                                        onChange={(e) => validateBet(e.target.value)}
                                        onBlur={(e) => handleBlur(e.target.value)}
                                        value={betInput}
                                        type="text"
                                        className={styles.inputAd}
                                        style={{width: "100%"}}
                                    />
                                    <div className={styles.btnInputWrapper}>
                                        <button
                                            onClick={() => betOperation("division")}
                                            className={styles.btnInput}
                                        >
                                            <p>1/2</p>
                                        </button>
                                        <button
                                            onClick={() => betOperation("multiple")}
                                            className={styles.btnInput}
                                        >
                                            <p>2x</p>
                                        </button>
                                        <button
                                            onClick={() => betOperation("max")}
                                            className={styles.btnInput}
                                        >
                                            <p>Max</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.betWrapper}>
                            <div style={{width: "100%"}}>
                                <h3>Profit</h3>
                                <div
                                    className={styles.betInputWrapper}
                                    style={{width: "100%"}}
                                >
                                    <img
                                        src={currencyIcon}
                                        style={{width: 25, height: 25}}
                                        alt="currency"
                                    />
                                    <input
                                        value={(Number(bet) * Number(multiplier)).toFixed(decimalsByCurrency[currency])}
                                        readOnly
                                        type="text"
                                        className={styles.inputAd}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>
                            <div style={{width: "100%"}}>
                                <h3>Payout</h3>
                                <div
                                    className={styles.betInputWrapper}
                                    style={{width: "100%"}}
                                >
                                    <img src={cross} style={{width: 18, height: 15}} alt=""/>
                                    <input
                                        value={multiplier}
                                        type="text"
                                        className={styles.inputAd}
                                        style={{width: "100%"}}
                                        onChange={(e) => {
                                            const newMultiplier = Number(e.target.value);
                                            setMultiplier(e.target.value)
                                            if (newMultiplier >= 1.0102 && newMultiplier <= 9900) {
                                                const newValue = calculateValueFromMultiplier(newMultiplier);
                                                setValue(newValue);
                                            }

                                            if (newMultiplier < 1.0102) {
                                                notify("Value must be greater than or equal to 1.0102", "info");
                                            } else if (newMultiplier > 9900) {
                                                notify("Value must be less than or equal to 9900", "info");
                                            }
                                        }}
                                        onBlur={() => {
                                            const newMultiplier = Number(multiplier);
                                            if (newMultiplier >= 1.0102 && newMultiplier <= 9900) {
                                                setMultiplier((Math.round(newMultiplier * 10000) / 10000).toFixed(4));
                                            } else {
                                                let finalMultiplier = 0;
                                                if (newMultiplier < 1.0102) {
                                                    setMultiplier("1.0102");
                                                    finalMultiplier = 1.0102;
                                                } else if (newMultiplier > 9900) {
                                                    setMultiplier("9900.0000");
                                                    finalMultiplier = 9900;
                                                } else {
                                                    finalMultiplier = (Math.round(multiplierCalculating * 10000) / 10000);
                                                    setMultiplier(finalMultiplier.toFixed(4));
                                                }

                                                const newValue = calculateValueFromMultiplier(finalMultiplier);
                                                setValue(newValue);
                                            }
                                        }}
                                        onClick={(e) => {
                                            const input = e.target as HTMLInputElement;
                                            input.select();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.betWrapper}>
                            <div style={{width: "100%"}}>
                                <h3>Roll {flipStatus}</h3>
                                <div
                                    className={styles.betInputWrapper}
                                    style={{width: "100%"}}
                                >
                                    <input
                                        readOnly
                                        value={value}
                                        type="text"
                                        className={styles.inputAd}
                                        style={{width: "100%"}}
                                    />
                                    <div className={styles.btnInputWrapper} style={{right: 16}}>
                                        <button
                                            onClick={changeFlipStatus}
                                            className={styles.btnInput}
                                        >
                                            <img src={flip} alt=""/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "100%"}}>
                                <h3>Win Chance</h3>
                                <div
                                    className={styles.betInputWrapper}
                                    style={{width: "100%"}}
                                >
                                    <img src={percent} style={{width: 21, height: 17}} alt=""/>
                                    <input
                                        type="text"
                                        readOnly
                                        value={valueCalculating}
                                        className={styles.inputAd}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.betWrapper}>
                            <button
                                onClick={startGame}
                                disabled={startDiceGame}
                                className={styles.primaryBtn}
                            >
                                <p>Place Bet</p>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{display: "none"}}>{currentBalance}</div>
        </div>
    );
};
