import DimensionsProvider from "./dimensions/DimensionsProvider";
import MinesProvider from "./mines/MinesProvider";
import PlinkoProvider from "./plinko/PlinkoProvider";
import {HttpProvider} from "./http/HTTPProvider";
import TowersProvider from "./towers/TowersProvider";

export default function Providers({children}: any) {
    return (
        <DimensionsProvider>
            <TowersProvider>
                <MinesProvider>
                    <PlinkoProvider>
                        <HttpProvider>
                            {children}
                        </HttpProvider>
                    </PlinkoProvider>
                </MinesProvider>
            </TowersProvider>
        </DimensionsProvider>
    );
}
