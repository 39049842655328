import React from "react";
import { Mode } from "../../Games/Towers/types";

interface IHttpContext {
    currency: string;
    getBalance: () => number;
    cryptoBalance: any;
    exchangeRate: number;
    hasBalance: (betValue: number) => boolean;
    decimalsByCurrency: { [key: string]: number };
    setCurrency: (currency: string) => void;
    sendUpdateToParent(symbol: string, amount: string, action?: string): void;
    getTowersGameState: () => Promise<void>;
    createTowers: (betValue: number, mode: Mode) => Promise<void>;
    actionTowers: (gameID: number, index: number) => Promise<void>;
    finishTowers: (gameID: number) => Promise<void>;
    getMinesGameState: () => Promise<void>;
    createMines: (betValue: number, numMines: number) => Promise<void>;
    actionMines: (gameID: number, action: string, cell: number, indexX: number, indexY: number) => Promise<void>;
    diceGame: (betValue: number, mode: any, rollValue: number, playDice: any) => Promise<void>;
    getDiceGameState: (setBalance: any) => Promise<void>;
    // finishMines: () => Promise<void>;
    getPlinkoGameState: (setBalance: any) => Promise<void>;
    plinkoGame: (
        lines: any,
        betValue: number,
        mode: any,
        mobile: boolean
    ) => Promise<void>;
}

export const HttpContext = React.createContext<IHttpContext>({
    currency: "USD",
    getBalance: () => 0,
    cryptoBalance: 0,
    exchangeRate: 0,
    hasBalance: () => false,
    decimalsByCurrency: {},
    setCurrency: () => {},
    sendUpdateToParent: () => {},
    getTowersGameState: async () => {},
    createTowers: async () => {},
    actionTowers: async () => {},
    finishTowers: async () => {},
    getMinesGameState: async () => {},
    createMines: async () => {},
    actionMines: async () => {},
    diceGame: async () => {},
    getDiceGameState: async () => {},
    // finishMines: async () => {},
    getPlinkoGameState: async () => {},
    plinkoGame: async () => {},
});